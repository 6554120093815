.CTACard {
    .cell > & {
        height: 100%;
    }

    &[data-component-theme="white"] {
        background-color: #fff;
    }

    &[data-component-theme="light"] {
        background-color: brand-color(light-grey);
    }
    transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    &:hover {
        box-shadow: 0 6px 16px rgba(21, 21, 21, 0.08), 0px 1px 4px rgba(21, 21, 21, 0.06);
    }
    a {
        text-decoration: none;
    }
    figure {
        &.image-wrapper {
            padding-bottom: calc((9 / 16) * 100%); //%-based padding top/bottom that represents the 16:9 ratio (9/16 = 56.25%)
            width: 100%;
            overflow: hidden;
            margin: 0;
            position: relative;
            background: #000;

            &:after {
                background-image: linear-gradient(to top right, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.6));
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
            }

            > img {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                transition: opacity 0.2s ease-in-out;
            }
        }
    }

    .content-container {
        padding: 22px;
        width: 100%;
        .title {
            padding-bottom: 10px;
            font-size: rem-calc(24);
            @include ifbrand("nrma") {
                @include font_GTEestiProDisplayBold();
            }
            @include ifbrand("racv") {
                @include font_SuisseIntlBold();
            }
            @include breakpoint(small only) {
                font-size: rem-calc(22);
            }
            color: brand-color(dark);
            line-height: 1.25;
            text-decoration: none;
        }

        .content {
            font-size: rem-calc(18);
            @include breakpoint(small only) {
                font-size: rem-calc(16);
            }
            padding-bottom: 10px;
            color: brand-color(content-text);
            line-height: 1.25;
            p {
                font-size: rem-calc(18);
                @include breakpoint(small only) {
                    font-size: rem-calc(16);
                }
            }
        }
    }
}
