@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../scss/config/all";

.LocationLookup {
    .location-heading-container {
        text-align: center;
    }

    //global blurb component?
    .blurb {
        font-size: rem-calc(20);
        text-align: center;
        margin-bottom: 30px;

        @include breakpoint(medium down) {
            font-size: rem-calc(16);
        }
    }

    .LocationForm {
        @include breakpoint(small only) {
            margin-right: 0px;
        }

        .location-entry-label {
            position: absolute;
            top: 0;
            right: 0;
            color: brand-color(brown-grey);
            font-size: 20px;
            cursor: pointer;

            @include ifbrand("racv") {
                text-decoration: underline;
                color: brand-color(primary-alt);
            }

            @include ifbrand("ract") {
                color: brand-color(body-font-color);
            }

            @include ifbrand("racwa") {
                text-decoration: underline;
                color: brand-color(secondary);
                @include font_StagSansBook()
            }

            @include ifbrand("raa") {
                text-decoration: underline;
                color: brand-color(primary-alt);
                @include font_NationalMedium();
                font-size: rem-calc(18);
            }

            @include breakpoint(small only) {
                font-size: rem-calc(16);
            }

            &:hover,
            &:focus,
            &:active {
                color: brand-color(primary);

                @include ifbrand("racv") {
                    color: brand-color(primary-alt-hover);
                }

                @include ifbrand("racwa") {
                    color: brand-color(secondary-hover);
                }

            }

            &:focus {
                @include ifbrand("racv") {
                    outline: 2px solid brand-color(primary-alt);
                }
            }
        }

        .geo-location-button {
            margin-top: 29px;

            @include ifbrand("racwa") {
                margin-top: 0px;
                margin-bottom: 30px;
            }

            @include breakpoint(small only) {
                margin-top: 0px;
            }
        }
    }
}