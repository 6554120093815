@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../scss/config/all";
@import "../../scss/global";

.AppointmentContainer {
    .BatteryCardConfirmContainer {
        margin-bottom: 20px;
    }

    .AppointmentSummary,
    .VehicleSummary {
        .summary-readonly {
            .field-container {
                height: auto;
                margin-bottom: unset;
            }
        }
    }

    .AppointmentSummary {
        .summary-readonly {
            @include ifbrand("racwa") {
                align-items: flex-start;
                text-align: left;

                p {
                    font-size: rem-calc(16);
                }
            }

            .FieldContainer {
                label {
                    justify-content: center;

                    @include ifbrand("racwa") {
                        justify-content: flex-start;
                        margin-bottom: 1rem;
                        font-size: rem-calc(18);
                    }
                }

                input {
                    @include ifbrand("nrma") {
                        @include font_GTEestiProDisplayBold();
                    }

                    @include ifbrand("racv") {
                        @include font_SuisseIntlBold();
                    }

                    @include ifbrand("ract") {
                        @include font_PoppinsSemiBold();
                    }

                    @include ifbrand("racwa") {
                        text-align: left;
                        padding: 0;
                        font-size: rem-calc(16);
                        @include font_StagSansBook();
                    }

                    @include ifbrand("raa") {
                        font-size: rem-calc(20);
                        @include font_NationalBold();
                    }
                }
            }
        }
    }

    .CustomerDetailsSummary {
        .BookingDetailsSummary {

            .VehicleSummary,
            .BatterySummary,
            .LocationSummary {
                border-bottom: 1px solid brand-color(gray);
                padding: 20px 0;
            }

            .booking-details-row {
                gap: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .booking-details-row-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 15px;

                    .battery-details-container {
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: flex-start;
                        gap: 20px;

                        @include breakpoint(medium) {
                            align-items: center;
                        }

                        .battery-image {
                            padding: 10px;
                            width: 100px;
                            background-color: #fff;
                            border: 1px solid brand-color(primary);
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            @include breakpoint(medium) {
                                padding: 10px 30px;
                                width: 150px;
                                min-height: 120px;
                            }
                        }

                        .battery-details {
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                        }
                    }
                }
            }
        }
    }

    .edit-button-container {
        width: 100%;
        text-align: center;
    }

}

.summary-readonly {
    padding: 15px;
    min-height: $input-height;
    border-radius: $global-radius;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    background-color: white;
    font-size: rem-calc(20);

    &.appointment {
        @include ifbrand("racwa") {
            @include RACWA_MessageBox_Neutral_Style();
        }
    }

    &.vehicle {
        @include ifbrand("racwa") {
            background-color: brand-color(primary);

            input,
            input::placeholder {
                color: white !important;
                @include font_StagSansBook();
            }
        }
    }

    input {
        margin-bottom: 0;
        color: #000;

        @include breakpoint(medium) {
            @include line-clamp($font-size: 20px, $line-height: 1, $lines-to-show: 1);
        }

        @include ifbrand("ract") {
            color: brand-color(secondary);
        }
    }

    p {
        margin-bottom: 0px;
        margin-top: 7px;
    }
}