html,
body {
    overflow-x: hidden;
}

.content-body {
    justify-content: center;
    align-items: center;
}

.container {
    padding: 50px 0;
}

// TODO: Add to grid.scss ?
.grid-container {
    &.wide {
        max-width: 1400px;
    }

    &.wide-container-large {
        @include breakpoint(large) {
            width: 1249px;
            max-width: 1249px;
        }
    }

    &.narrow {
        max-width: 800px + map-get($grid-container-padding, medium); // so total inner width is 960px

        @include breakpoint(large) {
            max-width: 800px + map-get($grid-container-padding, large); // so total inner width is 960px
        }

        @include breakpoint(xlarge) {
            max-width: 800px + map-get($grid-container-padding, xlarge); // so total inner width is 960px
        }
    }

    @include breakpoint(small only) {
        &.full-small-only {
            padding-right: 0;
            padding-left: 0;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @include breakpoint(medium only) {
        &.full-medium-only {
            padding-right: 0;
            padding-left: 0;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @include breakpoint(large) {
        &.full-large-only {
            padding-right: 0;
            padding-left: 0;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .cell {
        &.margin-collapse {
            margin-left: 0px;
            margin-right: 0px;
        }

        &.small-margin-collapse {
            margin-left: 0px;
            margin-right: 0px;
        }

        @include breakpoint(medium) {
            &.medium-margin-collapse {
                margin-left: 0px;
                margin-right: 0px;
            }
        }

        @include breakpoint(large) {
            &.large-margin-collapse {
                margin-left: 0px;
                margin-right: 0px;
            }
        }

        @include breakpoint(xlarge) {
            &.xlarge-margin-collapse {
                margin-left: 0px;
                margin-right: 0px;
            }
        }
    }
}

#wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    background: brand-color(content-body);
}

.content-row {
    .content-row-heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 40px;

        h3 {
            margin: 0px;

            @include ifbrand("nrma") {
                font-size: rem-calc(35);
            }

            @include ifbrand("racv") {
                font-size: rem-calc(33);
            }

            @include breakpoint(small only) {
                font-size: rem-calc(20);
            }
        }
    }
}

[data-theme="white"] {
    background: white !important;
}

[data-theme="light"] {
    background: brand-color(content-body) !important;
}

section {
    margin-bottom: 24px;
}

a {
    text-decoration: underline;

    @include ifbrand("racv") {
        color: brand-color(primary-alt);
    }

    @include ifbrand("raa") {
        color: brand-color(primary-alt);
    }

    @include ifbrand("racwa") {
        color: #0062B2;
        font-weight: bold;
    }

    &:hover,
    &:focus {
        color: brand-color(primary-alt-hover);

        @include ifbrand("racwa") {
            color: darken(#0062B2, 10%);
        }

        @include ifbrand("raa") {
            color: brand-color(primary-alt-hover);
        }
    }

    &:focus {
        @include ifbrand("nrma") {
            outline-color: brand-color(primary);
        }

        @include ifbrand("racv") {
            outline-color: brand-color(primary-alt);
        }

        @include ifbrand("raa") {
            color: brand-color(primary-alt-hover);
        }
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    letter-spacing: normal;
}

@include breakpoint(large) {
    ::-webkit-scrollbar {
        width: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(#000, 0.3);
        border-radius: 15px;
        border: 5px solid #fff;

        &:hover {
            background-color: brand-color(primary);
        }
    }

    ::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 15px;
    }
}

.LoadingContainer {
    position: relative;
    margin: 0;
    padding: 0;
}

//Utils
.margin-none {
    margin: 0 !important;
}

.margin-none-desktop {
    @include breakpoint(medium up) {
        margin: 0 !important;
    }
}

.margin-vertical-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.margin-vertical-md {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}

.margin-vertical-md-lg {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
}

.margin-vertical-lg {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.margin-bottom-xs {
    margin-bottom: 5px !important;
}

.margin-bottom-sm {
    margin-bottom: 1rem !important;
}

.margin-bottom-md {
    margin-bottom: 24px !important;
}

.margin-bottom-md-lg {
    margin-bottom: 36px !important;
}

.margin-bottom-lg {
    margin-bottom: 40px !important;
}

.margin-top-xs {
    margin-bottom: 5px !important;
}

.margin-top-sm {
    margin-top: 1rem !important;
}

.margin-top-md {
    margin-top: 24px !important;
}

.margin-top-md-lg {
    margin-top: 36px !important;
}

.margin-top-lg {
    margin-top: 40px !important;
}

.text-divider {
    --text-divider-gap: 1rem;
    display: flex;
    align-items: center;
    font-size: 21px;

    &:before,
    &:after {
        content: '';
        height: 1px;
        background-color: brand-color(gray);
        flex-grow: 1;
    }

    &:before {
        margin-right: var(--text-divider-gap);
    }

    &:after {
        margin-left: var(--text-divider-gap);
    }
}
