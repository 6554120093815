@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?q23c7h') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?q23c7h') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?q23c7h') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?q23c7h##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.brand-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brand-icon-arrow-slick-next {
  &:before {
    content: $brand-icon-arrow-slick-next; 
  }
}
.brand-icon-arrow-slick-prev {
  &:before {
    content: $brand-icon-arrow-slick-prev; 
  }
}
.brand-icon-logout {
  &:before {
    content: $brand-icon-logout; 
  }
}
.brand-icon-edit-outline {
  &:before {
    content: $brand-icon-edit-outline; 
  }
}
.brand-icon-tick {
  &:before {
    content: $brand-icon-tick; 
  }
}
.brand-icon-location-arrow-outline {
  &:before {
    content: $brand-icon-location-arrow-outline; 
  }
}
.brand-icon-info-circle {
  &:before {
    content: $brand-icon-info-circle; 
  }
}
.brand-icon-tick-circle {
  &:before {
    content: $brand-icon-tick-circle; 
  }
}
.brand-icon-arrow-circle-slick-prev {
  &:before {
    content: $brand-icon-arrow-circle-slick-prev; 
  }
}
.brand-icon-arrow-circle-slick-next {
  &:before {
    content: $brand-icon-arrow-circle-slick-next; 
  }
}
.brand-icon-chevron-up {
  &:before {
    content: $brand-icon-chevron-up; 
  }
}
.brand-icon-chevron-right {
  &:before {
    content: $brand-icon-chevron-right; 
  }
}
.brand-icon-chevron-left {
  &:before {
    content: $brand-icon-chevron-left; 
  }
}
.brand-icon-chevron-down {
  &:before {
    content: $brand-icon-chevron-down; 
  }
}
.brand-icon-cog {
  &:before {
    content: $brand-icon-cog; 
  }
}
.brand-icon-warning-circle {
  &:before {
    content: $brand-icon-warning-circle; 
  }
}
.brand-icon-location-arrow {
  &:before {
    content: $brand-icon-location-arrow; 
  }
}
.brand-icon-sad-face {
  &:before {
    content: $brand-icon-sad-face; 
  }
}
.brand-icon-close-circle {
  &:before {
    content: $brand-icon-close-circle; 
  }
}
.brand-icon-ract-wrench {
  &:before {
    content: $brand-icon-ract-wrench; 
  }
}
.brand-icon-racv-slick-next {
  &:before {
    content: $brand-icon-racv-slick-next; 
  }
}
.brand-icon-ract-slick-prev {
  &:before {
    content: $brand-icon-ract-slick-prev; 
  }
}
.brand-icon-ract-battery {
  &:before {
    content: $brand-icon-ract-battery; 
  }
}
.brand-icon-ract-calendar-tick {
  &:before {
    content: $brand-icon-ract-calendar-tick; 
  }
}
.brand-icon-ract-location-pin {
  &:before {
    content: $brand-icon-ract-location-pin; 
  }
}
.brand-icon-ract-vehicle {
  &:before {
    content: $brand-icon-ract-vehicle; 
  }
}
.brand-icon-ract-info-circle {
  &:before {
    content: $brand-icon-ract-info-circle; 
  }
}
.brand-icon-ract-recycle {
  &:before {
    content: $brand-icon-ract-recycle; 
  }
}
.brand-icon-ract-time-arrow {
  &:before {
    content: $brand-icon-ract-time-arrow; 
  }
}
.brand-icon-ract-edit-outline {
  &:before {
    content: $brand-icon-ract-edit-outline; 
  }
}
.brand-icon-slick-next {
  &:before {
    content: $brand-icon-slick-next; 
  }
}
.brand-icon-nrma-slick-prev {
  &:before {
    content: $brand-icon-nrma-slick-prev; 
  }
}
.brand-icon-nrma-recycle {
  &:before {
    content: $brand-icon-nrma-recycle; 
  }
}
.brand-icon-nrma-battery {
  &:before {
    content: $brand-icon-nrma-battery; 
  }
}
.brand-icon-nrma-location-pin {
  &:before {
    content: $brand-icon-nrma-location-pin; 
  }
}
.brand-icon-nrma-calendar-tick {
  &:before {
    content: $brand-icon-nrma-calendar-tick; 
  }
}
.brand-icon-nrma-delivery {
  &:before {
    content: $brand-icon-nrma-delivery; 
  }
}
.brand-icon-nrma-wrench {
  &:before {
    content: $brand-icon-nrma-wrench; 
  }
}
.brand-icon-nrma-calendar {
  &:before {
    content: $brand-icon-nrma-calendar; 
  }
}
.brand-icon-nrma-time {
  &:before {
    content: $brand-icon-nrma-time; 
  }
}
.brand-icon-nrma-warning-circle-outline {
  &:before {
    content: $brand-icon-nrma-warning-circle-outline; 
  }
}
.brand-icon-nrma-vehicle {
  &:before {
    content: $brand-icon-nrma-vehicle; 
  }
}
.brand-icon-raa-calendar-plus {
  &:before {
    content: $brand-icon-raa-calendar-plus; 
  }
}
.brand-icon-raa-battery-recycle {
  &:before {
    content: $brand-icon-raa-battery-recycle; 
  }
}
.brand-icon-raa-emergency-vehicle {
  &:before {
    content: $brand-icon-raa-emergency-vehicle; 
  }
}
.brand-icon-raa-warning-sad-face {
  &:before {
    content: $brand-icon-raa-warning-sad-face; 
  }
}
.brand-icon-raa-appointment {
  &:before {
    content: $brand-icon-raa-appointment; 
  }
}
.brand-icon-raa-battery {
  &:before {
    content: $brand-icon-raa-battery; 
  }
}
.brand-icon-raa-location {
  &:before {
    content: $brand-icon-raa-location; 
  }
}
.brand-icon-raa-vehicle {
  &:before {
    content: $brand-icon-raa-vehicle; 
  }
}
.brand-icon-raa-warning {
  &:before {
    content: $brand-icon-raa-warning; 
  }
}
.brand-icon-racv-wrench {
  &:before {
    content: $brand-icon-racv-wrench; 
  }
}
.brand-icon-racv-warning {
  &:before {
    content: $brand-icon-racv-warning; 
  }
}
.brand-icon-racv-slick-next1 {
  &:before {
    content: $brand-icon-racv-slick-next1; 
  }
}
.brand-icon-racv-slick-prev {
  &:before {
    content: $brand-icon-racv-slick-prev; 
  }
}
.brand-icon-racv-delivery {
  &:before {
    content: $brand-icon-racv-delivery; 
  }
}
.brand-icon-racv-vehicle {
  &:before {
    content: $brand-icon-racv-vehicle; 
  }
}
.brand-icon-racv-battery {
  &:before {
    content: $brand-icon-racv-battery; 
  }
}
.brand-icon-racv-battery-charge {
  &:before {
    content: $brand-icon-racv-battery-charge; 
  }
}
.brand-icon-racv-calendar {
  &:before {
    content: $brand-icon-racv-calendar; 
  }
}
.brand-icon-racv-calendar-tick {
  &:before {
    content: $brand-icon-racv-calendar-tick; 
  }
}
.brand-icon-racv-time {
  &:before {
    content: $brand-icon-racv-time; 
  }
}
.brand-icon-racv-time-arrow {
  &:before {
    content: $brand-icon-racv-time-arrow; 
  }
}
.brand-icon-racv-edit-outline {
  &:before {
    content: $brand-icon-racv-edit-outline; 
  }
}
.brand-icon-racv-info-circle {
  &:before {
    content: $brand-icon-racv-info-circle; 
  }
}
.brand-icon-racv-location-pin {
  &:before {
    content: $brand-icon-racv-location-pin; 
  }
}
.brand-icon-racv-recycle {
  &:before {
    content: $brand-icon-racv-recycle; 
  }
}
.brand-icon-racv-tick-circle-outline {
  &:before {
    content: $brand-icon-racv-tick-circle-outline; 
  }
}
.brand-icon-aant-square-close {
  &:before {
    content: $brand-icon-aant-square-close; 
  }
}
.brand-icon-aant-info-triangle {
  &:before {
    content: $brand-icon-aant-info-triangle; 
  }
}
.brand-icon-aant-location-pin {
  &:before {
    content: $brand-icon-aant-location-pin; 
  }
}
.brand-icon-aant-battery {
  &:before {
    content: $brand-icon-aant-battery; 
  }
}
.brand-icon-aant-vehicle {
  &:before {
    content: $brand-icon-aant-vehicle; 
  }
}
.brand-icon-aant-member-card {
  &:before {
    content: $brand-icon-aant-member-card; 
  }
}
.brand-icon-aant-sad-face {
  &:before {
    content: $brand-icon-aant-sad-face; 
  }
}
.brand-icon-aant-info-circle {
  &:before {
    content: $brand-icon-aant-info-circle; 
  }
}
.brand-icon-aant-tick {
  &:before {
    content: $brand-icon-aant-tick; 
  }
}
.brand-icon-racwa-square-close {
  &:before {
    content: $brand-icon-racwa-square-close; 
  }
}
.brand-icon-racwa-question-mark {
  &:before {
    content: $brand-icon-racwa-question-mark; 
  }
}
.brand-icon-racwa-thumb {
  &:before {
    content: $brand-icon-racwa-thumb; 
  }
}
.brand-icon-racwa-tick {
  &:before {
    content: $brand-icon-racwa-tick; 
  }
}
.brand-icon-racwa-info-circle {
  &:before {
    content: $brand-icon-racwa-info-circle; 
  }
}
.brand-icon-racwa-alert {
  &:before {
    content: $brand-icon-racwa-alert; 
  }
}
.brand-icon-racwa-appointment {
  &:before {
    content: $brand-icon-racwa-appointment; 
  }
}
.brand-icon-racwa-battery {
  &:before {
    content: $brand-icon-racwa-battery; 
  }
}
.brand-icon-racwa-calender {
  &:before {
    content: $brand-icon-racwa-calender; 
  }
}
.brand-icon-racwa-current-location {
  &:before {
    content: $brand-icon-racwa-current-location; 
  }
}
.brand-icon-racwa-delivery {
  &:before {
    content: $brand-icon-racwa-delivery; 
  }
}
.brand-icon-racwa-installation {
  &:before {
    content: $brand-icon-racwa-installation; 
  }
}
.brand-icon-racwa-location {
  &:before {
    content: $brand-icon-racwa-location; 
  }
}
.brand-icon-racwa-recycling {
  &:before {
    content: $brand-icon-racwa-recycling; 
  }
}
.brand-icon-racwa-search {
  &:before {
    content: $brand-icon-racwa-search; 
  }
}
.brand-icon-racwa-time {
  &:before {
    content: $brand-icon-racwa-time; 
  }
}
.brand-icon-racwa-vehicle {
  &:before {
    content: $brand-icon-racwa-vehicle; 
  }
}
.brand-icon-racq-roadside .path1 {
  &:before {
    content: $brand-icon-racq-roadside-path1;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-roadside .path2 {
  &:before {
    content: $brand-icon-racq-roadside-path2;  
    margin-left: -1.0478515625em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-roadside .path3 {
  &:before {
    content: $brand-icon-racq-roadside-path3;  
    margin-left: -1.0478515625em;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-roadside .path4 {
  &:before {
    content: $brand-icon-racq-roadside-path4;  
    margin-left: -1.0478515625em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-roadside .path5 {
  &:before {
    content: $brand-icon-racq-roadside-path5;  
    margin-left: -1.0478515625em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-roadside .path6 {
  &:before {
    content: $brand-icon-racq-roadside-path6;  
    margin-left: -1.0478515625em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-roadside .path7 {
  &:before {
    content: $brand-icon-racq-roadside-path7;  
    margin-left: -1.0478515625em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-roadside .path8 {
  &:before {
    content: $brand-icon-racq-roadside-path8;  
    margin-left: -1.0478515625em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-roadside .path9 {
  &:before {
    content: $brand-icon-racq-roadside-path9;  
    margin-left: -1.0478515625em;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-roadside .path10 {
  &:before {
    content: $brand-icon-racq-roadside-path10;  
    margin-left: -1.0478515625em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-roadside .path11 {
  &:before {
    content: $brand-icon-racq-roadside-path11;  
    margin-left: -1.0478515625em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-roadside .path12 {
  &:before {
    content: $brand-icon-racq-roadside-path12;  
    margin-left: -1.0478515625em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-roadside .path13 {
  &:before {
    content: $brand-icon-racq-roadside-path13;  
    margin-left: -1.0478515625em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-roadside .path14 {
  &:before {
    content: $brand-icon-racq-roadside-path14;  
    margin-left: -1.0478515625em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-tick-outline-mono {
  &:before {
    content: $brand-icon-racq-tick-outline-mono; 
  }
}
.brand-icon-racq-battery-mono {
  &:before {
    content: $brand-icon-racq-battery-mono; 
  }
}
.brand-icon-racq-delivery .path1 {
  &:before {
    content: $brand-icon-racq-delivery-path1;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-delivery .path2 {
  &:before {
    content: $brand-icon-racq-delivery-path2;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-delivery .path3 {
  &:before {
    content: $brand-icon-racq-delivery-path3;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-delivery .path4 {
  &:before {
    content: $brand-icon-racq-delivery-path4;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-delivery .path5 {
  &:before {
    content: $brand-icon-racq-delivery-path5;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-delivery .path6 {
  &:before {
    content: $brand-icon-racq-delivery-path6;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-warning .path1 {
  &:before {
    content: $brand-icon-racq-warning-path1;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-warning .path2 {
  &:before {
    content: $brand-icon-racq-warning-path2;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-warning .path3 {
  &:before {
    content: $brand-icon-racq-warning-path3;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-warning .path4 {
  &:before {
    content: $brand-icon-racq-warning-path4;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-warning .path5 {
  &:before {
    content: $brand-icon-racq-warning-path5;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-warning .path6 {
  &:before {
    content: $brand-icon-racq-warning-path6;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-battery .path1 {
  &:before {
    content: $brand-icon-racq-battery-path1;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-battery .path2 {
  &:before {
    content: $brand-icon-racq-battery-path2;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-battery .path3 {
  &:before {
    content: $brand-icon-racq-battery-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-battery .path4 {
  &:before {
    content: $brand-icon-racq-battery-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-battery .path5 {
  &:before {
    content: $brand-icon-racq-battery-path5;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-battery .path6 {
  &:before {
    content: $brand-icon-racq-battery-path6;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-battery .path7 {
  &:before {
    content: $brand-icon-racq-battery-path7;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-battery .path8 {
  &:before {
    content: $brand-icon-racq-battery-path8;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-battery .path9 {
  &:before {
    content: $brand-icon-racq-battery-path9;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-battery .path10 {
  &:before {
    content: $brand-icon-racq-battery-path10;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-calendar .path1 {
  &:before {
    content: $brand-icon-racq-calendar-path1;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-calendar .path2 {
  &:before {
    content: $brand-icon-racq-calendar-path2;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-calendar .path3 {
  &:before {
    content: $brand-icon-racq-calendar-path3;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-calendar .path4 {
  &:before {
    content: $brand-icon-racq-calendar-path4;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-calendar .path5 {
  &:before {
    content: $brand-icon-racq-calendar-path5;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-calendar .path6 {
  &:before {
    content: $brand-icon-racq-calendar-path6;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-calendar .path7 {
  &:before {
    content: $brand-icon-racq-calendar-path7;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-calendar .path8 {
  &:before {
    content: $brand-icon-racq-calendar-path8;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-calendar .path9 {
  &:before {
    content: $brand-icon-racq-calendar-path9;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-calendar .path10 {
  &:before {
    content: $brand-icon-racq-calendar-path10;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-calendar .path11 {
  &:before {
    content: $brand-icon-racq-calendar-path11;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-calendar .path12 {
  &:before {
    content: $brand-icon-racq-calendar-path12;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-calendar .path13 {
  &:before {
    content: $brand-icon-racq-calendar-path13;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-calendar .path14 {
  &:before {
    content: $brand-icon-racq-calendar-path14;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-calendar .path15 {
  &:before {
    content: $brand-icon-racq-calendar-path15;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-calendar .path16 {
  &:before {
    content: $brand-icon-racq-calendar-path16;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-calendar .path17 {
  &:before {
    content: $brand-icon-racq-calendar-path17;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-calendar .path18 {
  &:before {
    content: $brand-icon-racq-calendar-path18;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-calendar .path19 {
  &:before {
    content: $brand-icon-racq-calendar-path19;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-calendar .path20 {
  &:before {
    content: $brand-icon-racq-calendar-path20;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-calendar .path21 {
  &:before {
    content: $brand-icon-racq-calendar-path21;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-close-circle .path1 {
  &:before {
    content: $brand-icon-racq-close-circle-path1;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-close-circle .path2 {
  &:before {
    content: $brand-icon-racq-close-circle-path2;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-close-circle .path3 {
  &:before {
    content: $brand-icon-racq-close-circle-path3;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-close-circle .path4 {
  &:before {
    content: $brand-icon-racq-close-circle-path4;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-edit-outline .path1 {
  &:before {
    content: $brand-icon-racq-edit-outline-path1;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-edit-outline .path2 {
  &:before {
    content: $brand-icon-racq-edit-outline-path2;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-edit-outline .path3 {
  &:before {
    content: $brand-icon-racq-edit-outline-path3;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-edit-outline .path4 {
  &:before {
    content: $brand-icon-racq-edit-outline-path4;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-installation .path1 {
  &:before {
    content: $brand-icon-racq-installation-path1;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-installation .path2 {
  &:before {
    content: $brand-icon-racq-installation-path2;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-installation .path3 {
  &:before {
    content: $brand-icon-racq-installation-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-installation .path4 {
  &:before {
    content: $brand-icon-racq-installation-path4;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-installation .path5 {
  &:before {
    content: $brand-icon-racq-installation-path5;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-installation .path6 {
  &:before {
    content: $brand-icon-racq-installation-path6;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-installation .path7 {
  &:before {
    content: $brand-icon-racq-installation-path7;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-location-pin .path1 {
  &:before {
    content: $brand-icon-racq-location-pin-path1;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-location-pin .path2 {
  &:before {
    content: $brand-icon-racq-location-pin-path2;  
    margin-left: -0.9658203125em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-location-pin .path3 {
  &:before {
    content: $brand-icon-racq-location-pin-path3;  
    margin-left: -0.9658203125em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-location-pin .path4 {
  &:before {
    content: $brand-icon-racq-location-pin-path4;  
    margin-left: -0.9658203125em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-question-mark .path1 {
  &:before {
    content: $brand-icon-racq-question-mark-path1;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-question-mark .path2 {
  &:before {
    content: $brand-icon-racq-question-mark-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-question-mark .path3 {
  &:before {
    content: $brand-icon-racq-question-mark-path3;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-question-mark .path4 {
  &:before {
    content: $brand-icon-racq-question-mark-path4;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-question-mark .path5 {
  &:before {
    content: $brand-icon-racq-question-mark-path5;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-question-mark .path6 {
  &:before {
    content: $brand-icon-racq-question-mark-path6;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-question-mark .path7 {
  &:before {
    content: $brand-icon-racq-question-mark-path7;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-tick-outline .path1 {
  &:before {
    content: $brand-icon-racq-tick-outline-path1;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-tick-outline .path2 {
  &:before {
    content: $brand-icon-racq-tick-outline-path2;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-tick-outline .path3 {
  &:before {
    content: $brand-icon-racq-tick-outline-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-tick-outline .path4 {
  &:before {
    content: $brand-icon-racq-tick-outline-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-tick-outline .path5 {
  &:before {
    content: $brand-icon-racq-tick-outline-path5;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-tick-outline .path6 {
  &:before {
    content: $brand-icon-racq-tick-outline-path6;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-time .path1 {
  &:before {
    content: $brand-icon-racq-time-path1;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-time .path2 {
  &:before {
    content: $brand-icon-racq-time-path2;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-time .path3 {
  &:before {
    content: $brand-icon-racq-time-path3;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-time .path4 {
  &:before {
    content: $brand-icon-racq-time-path4;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-time .path5 {
  &:before {
    content: $brand-icon-racq-time-path5;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-time .path6 {
  &:before {
    content: $brand-icon-racq-time-path6;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-time .path7 {
  &:before {
    content: $brand-icon-racq-time-path7;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-time .path8 {
  &:before {
    content: $brand-icon-racq-time-path8;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-time .path9 {
  &:before {
    content: $brand-icon-racq-time-path9;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-time .path10 {
  &:before {
    content: $brand-icon-racq-time-path10;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-time .path11 {
  &:before {
    content: $brand-icon-racq-time-path11;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-vehicle .path1 {
  &:before {
    content: $brand-icon-racq-vehicle-path1;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-vehicle .path2 {
  &:before {
    content: $brand-icon-racq-vehicle-path2;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-vehicle .path3 {
  &:before {
    content: $brand-icon-racq-vehicle-path3;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-vehicle .path4 {
  &:before {
    content: $brand-icon-racq-vehicle-path4;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-vehicle .path5 {
  &:before {
    content: $brand-icon-racq-vehicle-path5;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-vehicle .path6 {
  &:before {
    content: $brand-icon-racq-vehicle-path6;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-vehicle-front .path1 {
  &:before {
    content: $brand-icon-racq-vehicle-front-path1;  
    color: rgb(255, 255, 255);
  }
}
.brand-icon-racq-vehicle-front .path2 {
  &:before {
    content: $brand-icon-racq-vehicle-front-path2;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-vehicle-front .path3 {
  &:before {
    content: $brand-icon-racq-vehicle-front-path3;  
    margin-left: -1em;  
    color: rgb(255, 230, 0);
  }
}
.brand-icon-racq-vehicle-front .path4 {
  &:before {
    content: $brand-icon-racq-vehicle-front-path4;  
    margin-left: -1em;  
    color: rgb(35, 58, 116);
  }
}
.brand-icon-racq-vehicle-front .path5 {
  &:before {
    content: $brand-icon-racq-vehicle-front-path5;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}
.brand-icon-racq-vehicle-front .path6 {
  &:before {
    content: $brand-icon-racq-vehicle-front-path6;  
    margin-left: -1em;  
    color: rgb(35, 58, 116);
  }
}
.brand-icon-racq-vehicle-front .path7 {
  &:before {
    content: $brand-icon-racq-vehicle-front-path7;  
    margin-left: -1em;  
    color: rgb(0, 52, 120);
  }
}

