footer {
    background-color: #fff;
    width: 100%;
    text-align: center;
    margin-top: auto;
    > .row {
        padding: 15px 0;
    }
    p {
        font-size: rem-calc(18);
        text-align: center;
        @include breakpoint(medium down) {
            font-size: rem-calc(14);
        }
        margin: 0;
        span.abn {
            @include breakpoint(medium down) {
                display: block;
            }
        }
    }
}
