@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

.ModalPopup {
    position: fixed; /* Stay in place */
    z-index: layer(modal); /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

    &.no-overflow {
        .modal-content {
            .modal-body {
                overflow: unset;
                max-height: unset;
                @include breakpoint(small only) {
                    max-height: 85vh;
                }
            }
        }
    }

    .modal-content {
        position: relative;
        background-color: brand-color(light-grey);
        @include ifbrand("racv") {
            background-color: white;
        }
        margin: auto;
        padding: 0;
        width: 50%;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        border-radius: $global-radius;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        animation-name: animatetop;
        animation-duration: 0.4s;
        h4 {
            font-size: rem-calc(26);
            margin-bottom: 3px;
            @include breakpoint(small only) {
                font-size: rem-calc(20);
            }
        }
        font-size: rem-calc(20);
        @include breakpoint(small only) {
            font-size: rem-calc(16);
        }
        p {
            font-size: rem-calc(20);
            color: brand-color(content-text);
            @include breakpoint(small only) {
                font-size: rem-calc(16);
            }
        }
        @include breakpoint(small only) {
            width: 95%;
        }
        .modal-header {
            position: relative;
            padding: 20px 16px;
            background-color: brand-color(primary);
            @include ifbrand("racv") {
                background-color: white;
            }
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-top-left-radius: $global-radius;
            border-top-right-radius: $global-radius;
            h4 {
                margin-bottom: 0;
                color: #fff;
                white-space: normal;
                @include ifbrand("racv") {
                    color: brand-color(secondary);
                }
            }
            .modal-close {
                cursor: pointer;
                color: #fff;
                @include ifbrand("racv") {
                    color: brand-color(primary-alt);
                }
                &:hover,
                &:focus {
                    opacity: 0.75;
                    @include ifbrand("racv") {
                        opacity: 1;
                        color: brand-color(primary-alt-hover);
                    }
                }
                .brand-icon {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .modal-body {
            padding: 20px;
            max-height: 85vh;
            overflow: auto;
            h4 {
                @include ifbrand("racv") {
                    color: brand-color(dark);
                }
            }
        }
        .modal-footer {
            padding: 20px;
            text-align: center;
            display: flex;
            .btn {
                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }

    &.MemberPopup {
        .modal-content {
            border: 2px solid brand-color(primary);
            padding: 20px 30px;
            background: white;
            display: flex;
            flex-direction: column;
            @include breakpoint(medium) {
                min-width: 640px;
            }
            @include breakpoint(small down) {
                padding-left: 10px;
                padding-right: 10px;
            }
            .modal-header,
            .modal-body,
            .modal-footer {
                background-color: white;
            }
            .modal-header {
                @include breakpoint(small down) {
                    padding-left: 10px;
                    padding-right: 10px;
                }
                h4 {
                    color: brand-color(dark);
                }
                .modal-close .brand-icon {
                    right: 0px;
                    top: 10%;
                }
            }
            .modal-body {
                @include breakpoint(small down) {
                    padding-left: 0px;
                    padding-right: 0px;
                }
                order: 3;
                .MembershipForm {
                    margin: 0;
                    > .grid-container {
                        padding: 0;
                        > .grid-x {
                            margin: 0;
                        }
                    }
                    .medium-10 {
                        width: calc(100% - 1.25rem);
                    }
                    .MembershipCTABox {
                        &:not(.success) {
                            padding: 0;
                            .membership-form-inner {
                                margin-top: 0;
                                @include breakpoint(small down) {
                                    .FieldContainer {
                                        padding-left: 10px;
                                        padding-right: 10px;
                                        .input-group {
                                            .input-group-button {
                                                button {
                                                    min-width: unset !important;
                                                }
                                            }
                                            #memberId {
                                                padding-left: 10px;
                                                padding-right: 20px;
                                            }
                                            .error-input {
                                                right: 8px;
                                            }
                                        }
                                    }
                                }
                                .error-text {
                                    color: brand-color(error);
                                    font-size: 14px;
                                    font-style: normal;
                                }
                            }
                        }
                    }
                }
            }
            .modal-footer {
                .btn.tertiaryoutline {
                    box-shadow: 0px 0px 0px 2px #000;
                    &:hover,
                    &:active,
                    &.active {
                        box-shadow: 0px 0px 0px 2px #ffe600;
                    }
                    &.active {
                        background-color: #ffe600;
                    }
                }
            }
        }
        &.success {
            .modal-body {
                order: 2;
            }
            .modal-footer {
                order: 3;
                .btn {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

@keyframes animatetop {
    from {
        top: -200px;
        opacity: 0;
    }
    to {
        top: 50%;
        opacity: 1;
    }
}
