@mixin btn($includeExtended: false) {
    cursor: pointer;
    width: 100%;
    height: 40px;
    padding: 0px 20px;
    outline: none;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: $button-border-radius;
    position: relative;
    pointer-events: all;
    line-height: 1.25;
    font-size: rem-calc(22);
    border: none;
    text-decoration: none;

    @include breakpoint(small only) {
        font-size: rem-calc(16);
    }

    @include breakpoint(medium) {
        width: auto;
    }

    @include ifdisabled() {
        pointer-events: none;
        cursor: not-allowed;
        background: $input-background-disabled !important;
        border-color: transparent;
        opacity: 0.5;

        @include ifbrand("racv") {
            background: transparent !important;
            color: brand-color(placeholder-grey) !important;
            border: 2px solid brand-color(placeholder-grey) !important;
            opacity: 1 !important;
        }

        @include ifbrand("ract") {
            background: transparent !important;
            color: brand-color(placeholder-grey) !important;
            border: 2px solid brand-color(placeholder-grey) !important;
            opacity: 1 !important;
        }

        @include ifbrand("racq") {
            background: transparent !important;
            color: brand-color(placeholder-grey) !important;
            border: 1px solid brand-color(placeholder-grey) !important;
            opacity: 1 !important;
        }

        @include ifbrand("racwa") {
            background: transparent !important;
            color: brand-color(placeholder-grey) !important;
            border: 1px solid brand-color(placeholder-grey) !important;
            opacity: 1 !important;
        }
    }

    // basic sub class styles
    .icon {
        margin-right: 5px;
        font-size: 1.5em;
    }

    &.iconleft {
        position: relative !important;

        .icon,
        .Icon {
            margin-left: 0px;
            margin-right: 5px;
            left: 0;
        }
    }

    &.iconright {
        flex-direction: row-reverse;
        position: relative;

        .icon,
        .Icon {
            margin-left: 5px;
            margin-right: 0px;
        }
    }

    span:not(.icon),
    span:not(.Icon) {
        @include ifbrand("racv") {
            padding-top: 2px;
        }
    }

    &.smallicon {

        .icon,
        .Icon {
            font-size: 0.9em;
        }
    }

    &.border-radius-sm,
    &.borderradiussm {
        border-radius: 6px !important;
    }

    &.autowidth {
        width: auto;
    }

    &.smallerwidth {
        min-width: 260px;
        padding: 0px 5px;

        @include breakpoint(medium down) {
            min-width: 100%;
            width: 100%;
        }

        &.iconright {

            .icon,
            .Icon {
                position: absolute;
                right: 20px;
                margin-right: 0px;
            }
        }
    }

    &.minwidth {
        min-width: 450px;
        padding: 0px 5px;

        @include breakpoint(medium down) {
            min-width: 100%;
            width: 100%;
        }

        &.iconright {

            .icon,
            .Icon {
                position: absolute;
                right: 20px;
                margin-right: 0px;
            }
        }
    }

    &.fullwidth {
        width: 100%;
        padding: 0px 5px;
    }

    &.spacebetween {
        justify-content: space-between;
        padding: 0 20px;
    }

    &.small {
        font-size: rem-calc(12);
        height: 25px;
        padding: 0px 10px;
        border-radius: $button-border-radius-small;

        @include ifbrand("racv") {
            @include font_SuisseIntlMedium();
            font-size: rem-calc(13);
        }
    }

    &.medium {
        font-size: rem-calc(18);
        height: 48px;
        padding: 0px 30px;
        border-radius: $button-border-radius-small;

        @include breakpoint(small only) {
            font-size: rem-calc(16);
        }

        @include ifbrand("nrma") {
            @include font_GTEestiProDisplayRegular();
        }

        @include ifbrand("racv") {
            @include font_SuisseIntlMedium();
        }
    }

    &.large {
        @include largeButton();
    }

    &.padded {
        @include breakpoint(medium) {
            padding: 0px 70px;
        }
    }

    &.ghost {
        background: transparent;
        color: brand-color(secondary);
        font-size: rem-calc(17);
        padding: 0;

        @include btnHoverState() {
            background: transparent!important;
            color: brand-color(primary);
            border: none!important;
            outline: none!important;
            box-shadow: none!important;

            @include ifbrand("racwa") {
                color: brand-color(secondary);
            }
        }

        @include ifbrand("racv") {

            &.iconright,
            &.iconleft {

                .icon,
                .Icon {
                    margin-bottom: 3px;
                }
            }
        }

        @include ifbrand("raa") {
            color: brand-color(primary);
        }

        @include ifbrand("racq") {
            color: brand-color(primary);
        }

        .btn-label {
            text-decoration: underline;
        }
    }

    // COLORS DEFAULT BTN
    background: brand-color(primary);
    color: #fff;

    @include ifbrand("racv") {
        background: brand-color(tertiary);
        color: brand-color(secondary);
    }

    @include ifbrand("ract") {
        background: brand-color(tertiary);
        color: #fff;
    }

    @include ifbrand("racwa") {
        background: brand-color(primary);
        color: white;
        font-size: rem-calc(20);
        @include font_StagSansBook();
    }

    @include ifbrand("raa") {
        background: brand-color(secondary);
        color: brand-color(body-font-color);
    }

    @include btnHoverState() {
        background: brand-color(primary-hover);
        color: #fff;

        @include ifbrand("racv") {
            background: brand-color(tertiary-hover);
            color: brand-color(secondary);
        }

        @include ifbrand("ract") {
            background: brand-color(tertiary-hover);
            color: #fff;
        }

        @include ifbrand("racwa") {
            color: #fff;
        }

        @include ifbrand("raa") {
            background: brand-color(secondary-hover);
            color: brand-color(body-font-color);
        }

        &:focus {
            @include ifbrand("nrma") {
                background: brand-color(primary-focus);
            }

            @include ifbrand("racv") {
                border: 2px solid brand-color(primary-alt);
                box-shadow: inset 0px 0px 0px 2px #fff;
            }

            @include ifbrand("raa") {
                background: brand-color(secondary-hover);
                color: brand-color(body-font-color);
            }

        }
    }

    // Brand specific styles
    @include ifbrand("nrma") {
        @include font_GTEestiProDisplayRegular();
    }

    @include ifbrand("racv") {
        @include font_SuisseIntlMedium();
    }

    @include ifbrand("racq") {
        @include font_ArialBold();
    }

    @include ifbrand("aant") {
        @include font_InterSemiBold();
    }

    @include ifbrand("raa") {
        @include font_NationalMedium();
    }

    @if $includeExtended {
        &.x-for-y {
            @include ifbrand("nrma") {
                text-transform: uppercase;
                @include font_GTEestiProDisplayRegular();
            }

            @include ifbrand("racv") {
                text-transform: uppercase;
                @include font_SuisseIntlMedium();
            }

            .for {
                @include ifbrand("nrma") {
                    @include font_GTEestiProDisplayRegular();
                }

                @include ifbrand("racv") {
                    @include font_SuisseIntlMedium();
                }
            }
        }

        &.light {
            background-color: #fff;
            color: brand-color(body);

            @include ifbrand("nrma") {
                &.althover {
                    @include btnHoverState() {
                        background: #000;
                        color: #fff;
                    }
                }
            }
        }

        &.hollow {
            @include btn-hollow();
        }

        &.secondary {
            @include btn-secondary();
        }

        &.tertiary {
            @include btn-tertiary();
        }

        &.tertiaryoutline {
            @include btn-tertiary-outline();
        }

        &.dark {
            @include btn-dark();
        }

        &.danger {
            @include btn-danger();
        }

        &.selected {
            cursor: default;
            background: brand-color(primary);

            @include ifbrand("nrma") {
                color: #fff;
            }

            @include ifbrand("racv") {
                .icon {
                    color: #fff;
                }
            }
        }

        &.ellipsis {
            display: inline-block;
            line-height: 40px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.small {
                line-height: 25px;
            }

            &.large {
                line-height: $input-height; // from mixin
            }
        }
    }
}

@mixin btnHoverState() {
    @include ifnotselectedoractive() {
        @include ifnotdisabled() {

            &:focus,
            &:hover,
            &:visited,
            &:active {
                @content;
            }
        }
    }
}

@mixin largeButton() {
    height: $input-height;

    @include breakpoint(small only) {
        height: 43px;
    }

    border-radius: $button-border-radius-large;

    .icon,
    .Icon {
        margin-right: 10px;
    }

    &.iconright {

        .icon,
        .Icon {
            margin-right: 0px;
        }
    }
}

@mixin btn-secondary() {
    background-color: #fff;
    color: brand-color(body-font-color);
    border: 2px solid brand-color(form-element-border);

    @include btnHoverState() {
        background-color: #fff;
        border: 2px solid brand-color(body-font-color);
        color: brand-color(primary);

        @include ifbrand("racwa") {
            background-color: #e6e6e6;
        }

        @include ifbrand("raa") {
            color: brand-color(body-font-color);
            border-color: #878787;
        }
    }
}

@mixin btn-tertiary() {
    background-color: brand-color(tertiary);

    @include ifbrand("nrma") {
        color: #fff;
    }

    @include ifbrand("racv") {
        color: brand-color(secondary);
    }

    @include ifbrand("racq") {
        color: brand-color(primary);
    }

    @include ifbrand("racwa") {
        color: brand-color(primary);
    }

    @include ifdisabled() {
        @include ifbrand("racwa") {
            background-color: brand-color(tertiary) !important;
            pointer-events: none !important;
            cursor: not-allowed !important;
            border-color: transparent !important;
            opacity: 0.35 !important;
        }
    }

    @include btnHoverState() {
        background-color: brand-color(tertiary-hover);

        @include ifbrand("nrma") {
            color: #fff;

            &:focus {
                background-color: brand-color(tertiary-focus);
            }
        }

        @include ifbrand("racv") {
            color: brand-color(secondary);
        }

        @include ifbrand("racq") {
            color: brand-color(primary);
        }

        @include ifbrand("racwa") {
            background-color: brand-color(primary);
            color: brand-color(tertiary);
        }
    }

    &.light,
    .dark & {
        color: #fff;
        border-color: #fff;

        @include btnHoverState() {
            @include ifbrand("nrma") {
                color: brand-color(secondary);
                border-color: brand-color(secondary);
            }

            @include ifbrand("racv") {
                color: brand-color(primary);
                border-color: brand-color(primary);
            }
        }
    }
}

@mixin btn-hollow() {
    background-color: #fff;
    color: brand-color(primary);
    border: 2px solid brand-color(primary);

    @include btnHoverState() {
        background-color: brand-color(primary-hover);
        border-color: transparent;
        color: #fff;
    }
}

@mixin btn-tertiary-outline() {
    @include ifbrand("racv") {
        background-color: #fff;
        color: brand-color(secondary);
        border: none;

        @include ifnotselectedoractive() {
            @include ifnotdisabled() {
                box-shadow: 0px 0px 0px 2px brand-color(tertiary);
            }
        }

        @include btnHoverState() {
            background-color: brand-color(primary-hover);
            color: #fff;
            border: none;

            @include ifbrand("racv") {
                color: brand-color(secondary);
                background-color: brand-color(tertiary);

                &:focus {
                    box-shadow: 0px 0px 0px 2px brand-color(primary-alt);
                    border: 2px solid white;
                }

                &:active,
                &:visited {
                    color: brand-color(secondary);
                    background-color: brand-color(tertiary);
                    border: none;
                    box-shadow: none;
                }
            }
        }
    }

    @include ifbrand("ract") {
        background-color: #fff;
        color: brand-color(body-font-color);
        border: none;

        @include ifnotselectedoractive() {
            @include ifnotdisabled() {
                //outline: 2px solid brand-color(tertiary);
                box-shadow: 0px 0px 0px 2px brand-color(tertiary);
            }
        }

        @include btnHoverState() {
            background-color: brand-color(primary-hover);
            color: #fff;
            border: none;

            @include ifbrand("ract") {
                background-color: brand-color(tertiary);
                color: #fff;

                &:focus {
                    box-shadow: 0px 0px 0px 2px brand-color(primary);
                    border: 2px solid white;
                }

                &:active,
                &:visited {
                    color: #fff;
                    background-color: brand-color(tertiary);
                    border: none;
                    box-shadow: none;
                }
            }
        }
    }

    @include ifbrand("racq") {
        background-color: #fff;
        color: brand-color(primary);
        border: none;
        //font-weight: normal;

        @include ifnotselectedoractive() {
            @include ifnotdisabled() {
                box-shadow: 0px 0px 0px 2px brand-color(tertiary);
            }
        }

        @include btnHoverState() {
            background-color: brand-color(primary-hover);
            color: #fff;
            border: none;

            @include ifbrand("racq") {
                color: brand-color(primary);
                background-color: brand-color(tertiary);

                &:focus {
                    box-shadow: 0px 0px 0px 2px brand-color(primary);
                    border: 2px solid white;
                }

                &:active,
                &:visited {
                    color: brand-color(primary);
                    background-color: brand-color(tertiary);
                    border: none;
                    box-shadow: none;
                }
            }
        }
    }

    @include ifbrand("raa") {
        background-color: #fff;
        color: brand-color(primary);
        border: 2px solid brand-color(placeholder-grey);

        @include ifnotselectedoractive() {
            @include ifnotdisabled() {
                // box-shadow: 0px 0px 0px 2px brand-color(secondary);
                border: 2px solid brand-color(secondary);
            }
        }

        @include btnHoverState() {
            background-color: brand-color(secondary-hover);
            border: 2px solid brand-color(secondary-hover);

            @include ifbrand("raa") {
                color: brand-color(primary);
                background-color: brand-color(secondary-hover);

                &:focus {
                    box-shadow: 0px 0px 0px 2px brand-color(primary-alt);
                }

                &:active,
                &:visited {
                    color: brand-color(primary);
                    background-color: brand-color(secondary);
                    border: none;
                    box-shadow: none;
                }
            }
        }
    }
}

@mixin btn-dark() {
    background-color: brand-color(dark);
    border: 2px solid brand-color(dark);
    color: #fff;

    @include btnHoverState() {
        background-color: brand-color(dark);
        color: #fff;
    }
}

@mixin btn-danger() {
    background-color: #f00;
    color: #fff;

    @include btnHoverState() {
        background-color: rgb(216, 0, 0);
    }
}

.ButtonContainer {
    margin-top: 24px; //40px - 1rem(16px)
}

.input-group-button {
    button {
        font-size: rem-calc(22);
        border-radius: $button-border-radius-large !important;

        @include breakpoint(small only) {
            font-size: rem-calc(16);
        }
    }
}