.CTACardCollection {
    padding: 65px 0 65px;
    background: brand-color(light-grey);
    @include breakpoint(small only) {
        padding: 40px 0 40px;
    }
    .cell {
        @include breakpoint(small down) {
            margin-top: 0px;
            margin-bottom: 15px;
        }
    }
}
