// * this settings file is for the FOUNDATION overrides only.
// * Any project related variables should be added to _variables.scss

$body-background: #fff;
$body-font-color: brand-color(body-font-color);
$paragraph-lineheight: 1.25;
$global-font-size: 16px;
$global-lineheight: 1;
$global-radius: 6px;

// * the below are set in each brand
// $body-font-family: "Gotham", Verdana, Geneva, Tahoma, sans-serif;
// $header-font-family: "Gotham", Verdana, Geneva, Tahoma, sans-serif;
// $header-font-weight: bold;

$responsive-embed-margin-bottom: 0px;

// * Breakpoints & Grid
$breakpoints: (
    small: 0,
    medium: 640px,
    large: 1024px,
    xlarge: 1200px,
);
$breakpoint-classes: (small medium large xlarge);

$grid-margin-gutters: (
    small: 7px * 2,
    medium: 8px * 2,
    large: 9px * 2,
    xlarge: 10px * 2,
);
// ! These values MUST ALWAYS be bigger than the gutters above (or you get Horizontal scrolling)
$grid-container-padding: (
    small: 12px * 2,
    medium: 15px * 2,
    large: 15px * 2,
    xlarge: 17px * 2,
);

$global-width: 1016px + map-get($grid-container-padding, xlarge); // so total inner width is 1016px

// linkss
$anchor-color: brand-color(primary);
$anchor-color-hover: brand-color(primary-alt);

// $select-background: #f6fafc;
// $select-radius: 24px;
$input-color: brand-color(body-font-color);
$input-placeholder-color: rgba(brand-color(body-font-color), 0.4);
$input-background: #fff;
$input-background-focus: #fff;
$input-background-disabled: #ddd;
$input-border: 1px solid #dadada;
$input-height: 60px;
// $input-line-height: 1;
$input-border-focus: 1px solid brand-color(body-font-color);
$input-padding: (18rem / 14);
$input-shadow: none;
$input-shadow-focus: none;
// $input-cursor-disabled: not-allowed;
// $input-transition: border-color 0.25s ease-in-out;
$input-number-spinners: false;
// $input-radius: $input-height / 2;

$form-label-color: brand-color(body-font-color);
$form-label-font-size: 21px;
$form-label-font-weight: bold;
$form-label-line-height: 1;

$helptext-color: brand-color(body-font-color);
$helptext-font-size: 14px;
$helptext-font-style: normal;

$header-lineheight: 1;
$header-margin-bottom-large: 32px;
$header-margin-bottom-medium: 28px;
$header-margin-bottom-small: 22px;
$header-color: brand-color(body-font-color);

// $input-prefix-color: $black;
// $input-prefix-background: $light-gray;
// $input-prefix-border: 1px solid $medium-gray;
// $input-prefix-padding: 1rem;

// $select-background: $white;
// $select-triangle-color: $dark-gray;
$select-radius: $global-radius;
// $input-color: $black;
// $input-placeholder-color: $medium-gray;
// $input-font-family: inherit;
$input-font-size: (18rem / 14);
// $input-font-weight: $global-weight-normal;
// $input-line-height: $global-lineheight;
// $input-background: $white;
// $input-background-focus: $white;
// $input-background-disabled: $light-gray;
// $input-border: 1px solid $medium-gray;
// $input-border-focus: 1px solid $dark-gray;
// $input-padding: $form-spacing / 2;
// $input-shadow: inset 0 1px 2px rgba($black, 0.1);
// $input-shadow-focus: 0 0 5px $medium-gray;
// $input-cursor-disabled: not-allowed;
// $input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
// $input-number-spinners: true;
$input-radius: $global-radius;
// $form-button-radius: $global-radius;
$header-styles: (
    "small": (
        "h1": (
            "font-size": 22,
            "line-height": 24,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-small,
        ),
        "h2": (
            "font-size": 20,
            "line-height": 24,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-small,
        ),
        "h3": (
            "font-size": 18,
            "line-height": 22,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-small,
        ),
        "h4": (
            "font-size": 17,
            "line-height": $header-lineheight,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-small,
        ),
        "h5": (
            "font-size": 16,
            "line-height": $header-lineheight,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-small,
        ),
        "h6": (
            "font-size": 15,
            "line-height": $header-lineheight,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-small,
        ),
    ),
    "medium": (
        "h1": (
            "font-size": 38,
            "line-height": $header-lineheight,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-medium,
        ),
        "h2": (
            "font-size": 32,
            "line-height": $header-lineheight,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-medium,
        ),
        "h3": (
            "font-size": 24,
            "line-height": $header-lineheight,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-medium,
        ),
        "h4": (
            "font-size": 22,
            "line-height": $header-lineheight,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-medium,
        ),
        "h5": (
            "font-size": 19,
            "line-height": $header-lineheight,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-medium,
        ),
        "h6": (
            "font-size": 16,
            "line-height": $header-lineheight,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-medium,
        ),
    ),
    "large": (
        "h1": (
            "font-size": 42,
            "line-height": 1,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-large,
        ),
        "h2": (
            "font-size": 35,
            "line-height": 1,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-large,
        ),
        "h3": (
            "font-size": 24,
            "line-height": 1,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-large,
        ),
        "h4": (
            "font-size": 22,
            "line-height": 1,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-large,
        ),
        "h5": (
            "font-size": 19,
            "line-height": 1,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-large,
        ),
        "h6": (
            "font-size": 16,
            "line-height": 1,
            "margin-top": 0,
            "margin-bottom": $header-margin-bottom-large,
        ),
    ),
);
