@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
//Config
@import "./scss/config/all";

@include foundation-global-styles;
@include foundation-typography;
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-xy-grid-classes; //possibly remove this as SXA is referencing grid system in html output?
@include foundation-forms;
@include foundation-visibility-classes;

//General
@import "./scss/global";
@import "./scss/forms";

//Nunjucks Components
@import "templates/components/Header/index";
@import "templates/components/Footer/index";
@import "templates/components/LoginForm/index";
@import "templates/components/RichText/index";
@import "templates/components/CTACard/index";
@import "templates/components/CTACardCollection/index";

.App {
    display: inline-block;
    width: 100%;
}