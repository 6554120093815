@import "assets/fonts/fonts";
@import "brand-fonts";
@import "brand-mixins";

$brand-colors: (
    primary: #1f5aa5,
    primary-hover: #005aff,
    primary-focus: #0900cc,
    primary-alt: #0067f6,
    primary-alt-hover: #003987,
    primary-alt-focus: #003987,
    primary-dark: #001657,
    secondary: #001657,
    secondary-hover: #154686,
    tertiary: #f8db00,
    tertiary-hover: #f2ca00,
    tertiary-focus: #f2ca00,
    progress-bar: #f8db00,
    success: #00cf3f,
    error: #e10004,
    table-cell-bg-danger: #FFE5E8,
    table-cell-bg-warning: #fff6e5,
    table-cell-bg-green: #E5F5F2,
    table-cell-color-danger: #e10004,
    table-cell-color-warning: #ffa500,
    table-cell-color-green:#009e81,
    content-text: #444444,
    body-font-color: #000,
    dark: #151515,
    charcoal-grey: #444444, 
    placeholder-grey: #706f76,
    blocker-dot-1: #1f5aa5,
    blocker-dot-2: lighten(#1f5aa5, 15%),
    blocker-dot-3: lighten(#1f5aa5, 30%),
    blocker-dot-4: lighten(#1f5aa5, 40%),
);

$header-height: (
    small: 70px,
    medium: 80px,
    large: 100px,
    xlarge: 100px,
);
$header-height-sticky: (
    small: 50px,
    medium: 55px,
    large: 60px,
    xlarge: 60px,
);

$button-border-radius: 40px;
$button-border-radius-small: 40px;
$button-border-radius-large: 40px;

// FOUNDATION SETTINGS
$body-font-family: "SuisseIntl-Regular", Arial, Helvetica, sans-serif;
// $header-font-family: "SuisseIntl-Bold", Arial, Helvetica, sans-serif;
$header-font-family: "Poppins-SemiBold", Arial, Helvetica, sans-serif;

$header-font-weight: 900;
$anchor-color: brand-color(primary);
$anchor-color-hover: brand-color(secondary);

$input-font-family: "SuisseIntl-Medium", Arial, Helvetica, sans-serif;
$input-font-weight: normal;
$global-radius: 6px;
$input-radius: 6px;
$select-radius: 6px;
