// * shared global SCSS variables go here.
// * the below colors are used as a fallback to "$brand-colors" (and related mixin)
$global-colors: (
    body: #333,
    content-body: #f1f1f1,
    light-grey: #f1f1f1,
    light-grey-alt: #dfdfdf,
    brown-grey: #828282,
    form-element-border: #979797,
    card-border: #e9e9e9,
    grey: #b8b8b8,
    progress-bar-grey: #d6d6d6,
);

$loadblocker-bg-opacity: 0.75;

$headerHeight: 131px;
$headerHeightMobile: 82px;
$bookingStepsContainerHeightDesktop: 168px;
$bookingStepsContainerHeightMobile: 62px;
$form-button-spacing: 10px;

$footer-top-margin: 50px;

$headerAniTimeFade: 0.15s !default;
$headerAniTimeHeight: 0.15s !default;
