@import "../../../scss/config/all";

.LoginForm {
    padding: 75px 0;
    background: brand-color(light-grey);
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .chbx {
        padding: 10px 0 27px;
        span.text {
            font-size: rem-calc(18);
            margin-top: 2px;
        }
    }
    .forgot-password {
        text-align: center;
        padding: 20px 0;
    }
}
