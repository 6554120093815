@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../scss/config/all";

.VehicleRegoSearch {
    .vehicle-rego-search-container {
        .FieldContainer {
            .field-container {
                height: 135px;

                .dot:before,
                .dot:after {
                    content: "";
                    height: 5px;
                    width: 5px;
                    position: absolute;
                    background: brand-color(primary-alt);

                    @include ifbrand("nrma") {
                        background: brand-color(primary);
                    }

                    border-radius: 50%;
                }

                .top-dots:before {
                    left: 10px;
                    top: 10px;
                }

                .top-dots:after {
                    right: 10px;
                    top: 10px;
                }

                .top-left-inset-dots:before {
                    left: 25%;
                    top: 10px;
                }

                .top-left-inset-dots:after {
                    left: 28%;
                    top: 10px;
                }

                .top-right-inset-dots:before {
                    right: 25%;
                    top: 10px;
                }

                .top-right-inset-dots:after {
                    right: 28%;
                    top: 10px;
                }

                .bottom-dots:before {
                    left: 10px;
                    bottom: 10px;
                }

                .bottom-dots:after {
                    bottom: 10px;
                    right: 10px;
                }

                input.txtRegistrationNumber-input {
                    font-weight: 600;
                    height: 100%;
                    color: brand-color(primary-alt);
                    text-align: center;
                    border: 3px solid brand-color(primary-alt);
                    padding-top: 20px;
                    padding-bottom: 5px;
                    letter-spacing: 3px;
                    font-size: 48px;

                    @include breakpoint(large) {
                        font-size: 56px;
                    }

                    @include breakpoint(xlarge) {
                        font-size: 67px;
                    }

                    @include ifbrand("nrma") {
                        font-size: 48px;
                        color: brand-color(primary);
                        border: 3px solid brand-color(primary);

                        @include breakpoint(large) {
                            font-size: 56px;
                        }

                        @include breakpoint(xlarge) {
                            font-size: 67px;
                        }
                    }

                    @include ifbrand("ract") {
                        font-size: 48px;
                        padding-top: 0px;
                        color: brand-color(primary);
                        letter-spacing: 2px;

                        @include breakpoint(large) {
                            font-size: 56px;
                        }

                        @include breakpoint(xlarge) {
                            font-size: 70px;
                        }
                    }

                    @include ifbrand("racq") {
                        font-size: 48px;
                        padding-top: 0px;
                        color: brand-color(primary);
                        letter-spacing: 1px;

                        @include breakpoint(large) {
                            font-size: 56px;
                        }

                        @include breakpoint(xlarge) {
                            font-size: 67px;
                        }
                    }

                    @include ifbrand("racwa") {
                        font-size: 48px;
                        padding-top: 7px;
                        color: brand-color(primary);
                        letter-spacing: 1px;
                        @include font_StagSansBook();
                        @include breakpoint(large) {
                            font-size: 56px;
                        }

                        @include breakpoint(xlarge) {
                            font-size: 67px;
                        }
                    }

                    @include ifbrand("aant") {
                        font-size: 48px;
                        padding-top: 7px;
                        color: brand-color(primary);
                        letter-spacing: 1px;
                        @include font_InterBold();
                        @include breakpoint(large) {
                            font-size: 56px;
                        }

                        @include breakpoint(xlarge) {
                            font-size: 67px;
                        }
                    }

                    @include ifbrand("raa") {
                        padding-top: 0px;
                        color: #130064; //brand-color(primary) !important;
                        letter-spacing: 0px;
                        @include font_NationalBold();
                    }

                    &::placeholder {
                        color: #ddd !important;
                        padding-top: 1rem !important;
                        transform: translateY(4px);

                        @include ifbrand("nrma") {
                            color: #b3bcf7 !important;
                            transform: translateY(2px);
                        }

                        @include ifbrand("nrma") {
                            color: #b8b8b8 !important;
                        }
                    }
                }
            }
        }
    }

    .vehicle-rego-confirm-container {
        h3 {
            @include ifbrand("nrma") {
                @include font_GTEestiProDisplayBold();
            }

            @include ifbrand("racv") {
                @include font_SuisseIntlBold();
            }

            @include ifbrand("racwa") {
                @include font_StagSansBook();
            }

            @include ifbrand("raa") {
                @include font_NationalBold();
            }
            &.retrieved-state {
                display: flex;
                justify-content: center;
                span {
                    text-align: left;
                }
            }
            span:first-child {
                @include ifbrand("nrma") {
                    @include font_GTEestiProDisplayRegular();
                }

                @include ifbrand("racv") {
                    @include font_SuisseIntlRegular();
                }

                @include ifbrand("raa") {
                    @include font_InterRegular();
                }
            }
        }

        .vehicle-rego-confirm-car-details {
            border: 3px solid brand-color(primary);
            border-radius: $global-radius;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            background: white;

            @include ifbrand("raa") {
                border: 3px solid brand-color(primary-alt);
            }

            &.error {
                border: 3px solid brand-color(error);
            }

            h3 {
                font-size: 44px;
                color: brand-color(primary);
                margin-bottom: 5px;
                @include ifbrand("raa") {
                    // @include font_NationalBold();
                    font-size: 46px;
                }
            }

            p {
                color: brand-color(primary);
                font-size: 20px;
                margin-bottom: 0;
                @include ifbrand("racwa") {
                    @include font_StagSansMedium();
                }
                @include ifbrand("raa") {
                    @include font_NationalBold();
                }
            }
        }

        .error-text {
            font-size: 25px;
        }
    }
}

.VehicleSearchSummary {
    font-size: rem-calc(18);
    @include ifbrand("raa") {
        @include font_NationalMedium();
    }
}

.VehicleForm {
    .FieldContainer {
        .input-group-button {
            select {
                @include ifbrand("raa") {
                    border: 2px solid brand-color(secondary);
                    border-radius: $select-radius;
                }
            }
        }
    }
}
