@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

[class*="info-circle"],
[class*="close-circle"] {
    &:focus {
        border-radius: 50%;
        outline: 2px solid brand-color(primary-alt);
    }
}

[class*="close-circle"] {
    padding: 1px;
}
