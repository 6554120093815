$icomoon-font-family: "clubassist-icons-icomoon" !default;
$icomoon-font-path: "fonts" !default;

$brand-icon-arrow-slick-next: unquote('"\\e90e"');
$brand-icon-arrow-slick-prev: unquote('"\\e90f"');
$brand-icon-logout: unquote('"\\e910"');
$brand-icon-edit-outline: unquote('"\\e911"');
$brand-icon-tick: unquote('"\\e912"');
$brand-icon-location-arrow-outline: unquote('"\\e913"');
$brand-icon-info-circle: unquote('"\\e914"');
$brand-icon-tick-circle: unquote('"\\e915"');
$brand-icon-arrow-circle-slick-prev: unquote('"\\e916"');
$brand-icon-arrow-circle-slick-next: unquote('"\\e917"');
$brand-icon-chevron-up: unquote('"\\e918"');
$brand-icon-chevron-right: unquote('"\\e919"');
$brand-icon-chevron-left: unquote('"\\e91a"');
$brand-icon-chevron-down: unquote('"\\e91b"');
$brand-icon-cog: unquote('"\\e91d"');
$brand-icon-warning-circle: unquote('"\\e91e"');
$brand-icon-location-arrow: unquote('"\\e925"');
$brand-icon-sad-face: unquote('"\\e928"');
$brand-icon-close-circle: unquote('"\\e92d"');
$brand-icon-ract-wrench: unquote('"\\e9d0"');
$brand-icon-racv-slick-next: unquote('"\\e91f"');
$brand-icon-ract-slick-prev: unquote('"\\e920"');
$brand-icon-ract-battery: unquote('"\\e921"');
$brand-icon-ract-calendar-tick: unquote('"\\e922"');
$brand-icon-ract-location-pin: unquote('"\\e923"');
$brand-icon-ract-vehicle: unquote('"\\e924"');
$brand-icon-ract-info-circle: unquote('"\\e926"');
$brand-icon-ract-recycle: unquote('"\\e927"');
$brand-icon-ract-time-arrow: unquote('"\\e9a8"');
$brand-icon-ract-edit-outline: unquote('"\\e9b4"');
$brand-icon-slick-next: unquote('"\\e9c4"');
$brand-icon-nrma-slick-prev: unquote('"\\e9c5"');
$brand-icon-nrma-recycle: unquote('"\\e9c6"');
$brand-icon-nrma-battery: unquote('"\\e9c7"');
$brand-icon-nrma-location-pin: unquote('"\\e9c8"');
$brand-icon-nrma-calendar-tick: unquote('"\\e9c9"');
$brand-icon-nrma-delivery: unquote('"\\e9ca"');
$brand-icon-nrma-wrench: unquote('"\\e9cb"');
$brand-icon-nrma-calendar: unquote('"\\e9cc"');
$brand-icon-nrma-time: unquote('"\\e9cd"');
$brand-icon-nrma-warning-circle-outline: unquote('"\\e9ce"');
$brand-icon-nrma-vehicle: unquote('"\\e9cf"');
$brand-icon-raa-calendar-plus: unquote('"\\e9bf"');
$brand-icon-raa-battery-recycle: unquote('"\\e9c1"');
$brand-icon-raa-emergency-vehicle: unquote('"\\e9c2"');
$brand-icon-raa-warning-sad-face: unquote('"\\e9c3"');
$brand-icon-raa-appointment: unquote('"\\e9b5"');
$brand-icon-raa-battery: unquote('"\\e9b6"');
$brand-icon-raa-location: unquote('"\\e9ba"');
$brand-icon-raa-vehicle: unquote('"\\e9bb"');
$brand-icon-raa-warning: unquote('"\\e9bc"');
$brand-icon-racv-wrench: unquote('"\\e929"');
$brand-icon-racv-warning: unquote('"\\e92a"');
$brand-icon-racv-slick-next1: unquote('"\\e92b"');
$brand-icon-racv-slick-prev: unquote('"\\e92c"');
$brand-icon-racv-delivery: unquote('"\\e92e"');
$brand-icon-racv-vehicle: unquote('"\\e92f"');
$brand-icon-racv-battery: unquote('"\\e930"');
$brand-icon-racv-battery-charge: unquote('"\\e931"');
$brand-icon-racv-calendar: unquote('"\\e932"');
$brand-icon-racv-calendar-tick: unquote('"\\e9a0"');
$brand-icon-racv-time: unquote('"\\e9a1"');
$brand-icon-racv-time-arrow: unquote('"\\e9a2"');
$brand-icon-racv-edit-outline: unquote('"\\e9a3"');
$brand-icon-racv-info-circle: unquote('"\\e9a4"');
$brand-icon-racv-location-pin: unquote('"\\e9a5"');
$brand-icon-racv-recycle: unquote('"\\e9a6"');
$brand-icon-racv-tick-circle-outline: unquote('"\\e9a7"');
$brand-icon-aant-square-close: unquote('"\\e9b3"');
$brand-icon-aant-info-triangle: unquote('"\\e9b1"');
$brand-icon-aant-location-pin: unquote('"\\e9b2"');
$brand-icon-aant-battery: unquote('"\\e9b7"');
$brand-icon-aant-vehicle: unquote('"\\e9b8"');
$brand-icon-aant-member-card: unquote('"\\e9b9"');
$brand-icon-aant-sad-face: unquote('"\\e9bd"');
$brand-icon-aant-info-circle: unquote('"\\e9be"');
$brand-icon-aant-tick: unquote('"\\e9c0"');
$brand-icon-racwa-square-close: unquote('"\\e90d"');
$brand-icon-racwa-question-mark: unquote('"\\e9ae"');
$brand-icon-racwa-thumb: unquote('"\\e9af"');
$brand-icon-racwa-tick: unquote('"\\e9b0"');
$brand-icon-racwa-info-circle: unquote('"\\e907"');
$brand-icon-racwa-alert: unquote('"\\e900"');
$brand-icon-racwa-appointment: unquote('"\\e901"');
$brand-icon-racwa-battery: unquote('"\\e902"');
$brand-icon-racwa-calender: unquote('"\\e903"');
$brand-icon-racwa-current-location: unquote('"\\e904"');
$brand-icon-racwa-delivery: unquote('"\\e905"');
$brand-icon-racwa-installation: unquote('"\\e906"');
$brand-icon-racwa-location: unquote('"\\e908"');
$brand-icon-racwa-recycling: unquote('"\\e909"');
$brand-icon-racwa-search: unquote('"\\e90a"');
$brand-icon-racwa-time: unquote('"\\e90b"');
$brand-icon-racwa-vehicle: unquote('"\\e90c"');
$brand-icon-racq-roadside-path1: unquote('"\\e991"');
$brand-icon-racq-roadside-path2: unquote('"\\e992"');
$brand-icon-racq-roadside-path3: unquote('"\\e993"');
$brand-icon-racq-roadside-path4: unquote('"\\e994"');
$brand-icon-racq-roadside-path5: unquote('"\\e995"');
$brand-icon-racq-roadside-path6: unquote('"\\e996"');
$brand-icon-racq-roadside-path7: unquote('"\\e997"');
$brand-icon-racq-roadside-path8: unquote('"\\e998"');
$brand-icon-racq-roadside-path9: unquote('"\\e999"');
$brand-icon-racq-roadside-path10: unquote('"\\e99a"');
$brand-icon-racq-roadside-path11: unquote('"\\e99b"');
$brand-icon-racq-roadside-path12: unquote('"\\e99c"');
$brand-icon-racq-roadside-path13: unquote('"\\e99d"');
$brand-icon-racq-roadside-path14: unquote('"\\e99e"');
$brand-icon-racq-tick-outline-mono: unquote('"\\e99f"');
$brand-icon-racq-battery-mono: unquote('"\\e990"');
$brand-icon-racq-delivery-path1: unquote('"\\e98a"');
$brand-icon-racq-delivery-path2: unquote('"\\e98b"');
$brand-icon-racq-delivery-path3: unquote('"\\e98c"');
$brand-icon-racq-delivery-path4: unquote('"\\e98d"');
$brand-icon-racq-delivery-path5: unquote('"\\e98e"');
$brand-icon-racq-delivery-path6: unquote('"\\e98f"');
$brand-icon-racq-warning-path1: unquote('"\\e91c"');
$brand-icon-racq-warning-path2: unquote('"\\e9a9"');
$brand-icon-racq-warning-path3: unquote('"\\e9aa"');
$brand-icon-racq-warning-path4: unquote('"\\e9ab"');
$brand-icon-racq-warning-path5: unquote('"\\e9ac"');
$brand-icon-racq-warning-path6: unquote('"\\e9ad"');
$brand-icon-racq-battery-path1: unquote('"\\e933"');
$brand-icon-racq-battery-path2: unquote('"\\e934"');
$brand-icon-racq-battery-path3: unquote('"\\e935"');
$brand-icon-racq-battery-path4: unquote('"\\e936"');
$brand-icon-racq-battery-path5: unquote('"\\e937"');
$brand-icon-racq-battery-path6: unquote('"\\e938"');
$brand-icon-racq-battery-path7: unquote('"\\e939"');
$brand-icon-racq-battery-path8: unquote('"\\e93a"');
$brand-icon-racq-battery-path9: unquote('"\\e93b"');
$brand-icon-racq-battery-path10: unquote('"\\e93c"');
$brand-icon-racq-calendar-path1: unquote('"\\e93d"');
$brand-icon-racq-calendar-path2: unquote('"\\e93e"');
$brand-icon-racq-calendar-path3: unquote('"\\e93f"');
$brand-icon-racq-calendar-path4: unquote('"\\e940"');
$brand-icon-racq-calendar-path5: unquote('"\\e941"');
$brand-icon-racq-calendar-path6: unquote('"\\e942"');
$brand-icon-racq-calendar-path7: unquote('"\\e943"');
$brand-icon-racq-calendar-path8: unquote('"\\e944"');
$brand-icon-racq-calendar-path9: unquote('"\\e945"');
$brand-icon-racq-calendar-path10: unquote('"\\e946"');
$brand-icon-racq-calendar-path11: unquote('"\\e947"');
$brand-icon-racq-calendar-path12: unquote('"\\e948"');
$brand-icon-racq-calendar-path13: unquote('"\\e949"');
$brand-icon-racq-calendar-path14: unquote('"\\e94a"');
$brand-icon-racq-calendar-path15: unquote('"\\e94b"');
$brand-icon-racq-calendar-path16: unquote('"\\e94c"');
$brand-icon-racq-calendar-path17: unquote('"\\e94d"');
$brand-icon-racq-calendar-path18: unquote('"\\e94e"');
$brand-icon-racq-calendar-path19: unquote('"\\e94f"');
$brand-icon-racq-calendar-path20: unquote('"\\e950"');
$brand-icon-racq-calendar-path21: unquote('"\\e951"');
$brand-icon-racq-close-circle-path1: unquote('"\\e952"');
$brand-icon-racq-close-circle-path2: unquote('"\\e953"');
$brand-icon-racq-close-circle-path3: unquote('"\\e954"');
$brand-icon-racq-close-circle-path4: unquote('"\\e955"');
$brand-icon-racq-edit-outline-path1: unquote('"\\e956"');
$brand-icon-racq-edit-outline-path2: unquote('"\\e957"');
$brand-icon-racq-edit-outline-path3: unquote('"\\e958"');
$brand-icon-racq-edit-outline-path4: unquote('"\\e959"');
$brand-icon-racq-installation-path1: unquote('"\\e95a"');
$brand-icon-racq-installation-path2: unquote('"\\e95b"');
$brand-icon-racq-installation-path3: unquote('"\\e95c"');
$brand-icon-racq-installation-path4: unquote('"\\e95d"');
$brand-icon-racq-installation-path5: unquote('"\\e95e"');
$brand-icon-racq-installation-path6: unquote('"\\e95f"');
$brand-icon-racq-installation-path7: unquote('"\\e960"');
$brand-icon-racq-location-pin-path1: unquote('"\\e961"');
$brand-icon-racq-location-pin-path2: unquote('"\\e962"');
$brand-icon-racq-location-pin-path3: unquote('"\\e963"');
$brand-icon-racq-location-pin-path4: unquote('"\\e964"');
$brand-icon-racq-question-mark-path1: unquote('"\\e965"');
$brand-icon-racq-question-mark-path2: unquote('"\\e966"');
$brand-icon-racq-question-mark-path3: unquote('"\\e967"');
$brand-icon-racq-question-mark-path4: unquote('"\\e968"');
$brand-icon-racq-question-mark-path5: unquote('"\\e969"');
$brand-icon-racq-question-mark-path6: unquote('"\\e96a"');
$brand-icon-racq-question-mark-path7: unquote('"\\e96b"');
$brand-icon-racq-tick-outline-path1: unquote('"\\e96c"');
$brand-icon-racq-tick-outline-path2: unquote('"\\e96d"');
$brand-icon-racq-tick-outline-path3: unquote('"\\e96e"');
$brand-icon-racq-tick-outline-path4: unquote('"\\e96f"');
$brand-icon-racq-tick-outline-path5: unquote('"\\e970"');
$brand-icon-racq-tick-outline-path6: unquote('"\\e971"');
$brand-icon-racq-time-path1: unquote('"\\e972"');
$brand-icon-racq-time-path2: unquote('"\\e973"');
$brand-icon-racq-time-path3: unquote('"\\e974"');
$brand-icon-racq-time-path4: unquote('"\\e975"');
$brand-icon-racq-time-path5: unquote('"\\e976"');
$brand-icon-racq-time-path6: unquote('"\\e977"');
$brand-icon-racq-time-path7: unquote('"\\e978"');
$brand-icon-racq-time-path8: unquote('"\\e979"');
$brand-icon-racq-time-path9: unquote('"\\e97a"');
$brand-icon-racq-time-path10: unquote('"\\e97b"');
$brand-icon-racq-time-path11: unquote('"\\e97c"');
$brand-icon-racq-vehicle-path1: unquote('"\\e97d"');
$brand-icon-racq-vehicle-path2: unquote('"\\e97e"');
$brand-icon-racq-vehicle-path3: unquote('"\\e97f"');
$brand-icon-racq-vehicle-path4: unquote('"\\e980"');
$brand-icon-racq-vehicle-path5: unquote('"\\e981"');
$brand-icon-racq-vehicle-path6: unquote('"\\e982"');
$brand-icon-racq-vehicle-front-path1: unquote('"\\e983"');
$brand-icon-racq-vehicle-front-path2: unquote('"\\e984"');
$brand-icon-racq-vehicle-front-path3: unquote('"\\e985"');
$brand-icon-racq-vehicle-front-path4: unquote('"\\e986"');
$brand-icon-racq-vehicle-front-path5: unquote('"\\e987"');
$brand-icon-racq-vehicle-front-path6: unquote('"\\e988"');
$brand-icon-racq-vehicle-front-path7: unquote('"\\e989"');

