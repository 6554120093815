@mixin font_SuisseIntlBold() {
    font-family: "SuisseIntl-Bold";
}

@mixin font_SuisseIntlMedium() {
    font-family: "SuisseIntl-Medium";
}

@mixin font_SuisseIntlRegular() {
    font-family: "SuisseIntl-Regular";
}

@mixin font_PoppinsSemiBold() {
    font-family: "Poppins-SemiBold";
}
