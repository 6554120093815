@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

.Heading {
    .heading-container {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        .heading {
            @include ifbrand("racwa") {
                @include font_StagSansBook();
                font-weight: normal;
            }
        }
        .brand-icon {
            font-size: rem-calc(20);
            margin-left: 7.5px;
            @include ifbrand("racv") {
                color: brand-color(primary-alt);
            }
            @include ifbrand("racq") {
                color: brand-color(primary);
            }
            @include ifbrand("raa") {
                color: brand-color(primary-alt);
            }
        }
        h2 {
            @include ifbrand("ract") {
                @include font_PoppinsBold();
            }
            @include ifbrand("racwa") {
                color: brand-color(primary-alt);
            }
            @include ifbrand("aant") {
                color: brand-color(primary);
            }
        }
        h3,
        h4 {
            margin-bottom: 1rem;
            margin-top: 1rem;
            @include ifbrand("racwa") {
                color: brand-color(primary-alt);
            }
            @include ifbrand("aant") {
                color: brand-color(primary);
            }
        }
    }
}

.BookingHeading {
    position: relative;
    min-height: 162px;
    margin-bottom: 115px;
    @include breakpoint(medium down) {
        min-height: 159px;
    }
    @include breakpoint(small only) {
        min-height: 139px;
        margin-bottom: 95px;
    }
    .brand-icon {
        font-size: rem-calc(132);
        &.success {
            color: brand-color(success);
            
        }
        &.neutral {
            color: brand-color(charcoal-grey);
            @include ifbrand("racwa") {
                color: brand-color(primary);
            }
        }
        position: absolute;
        left: 50%;
        bottom: -80px;
        transform: translateX(-50%);
        @include breakpoint(small only) {
            font-size: rem-calc(110);
            bottom: -65px;
        }
    }
}
