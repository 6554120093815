$layers: (
    header:10,
    mobileSubTitle: 11,
    progressBar: 12,
    slickArrows: 12,
    loadBlocker: 13,
    modal: 14,
);

@function layer($name) {
    @if map-has-key($layers, $name) {
        @return map-get($layers, $name);
    }
    @error "The key #{$name} is not in the map ’$layers’";
    @return null;
}
