// !IMPORTANT $brandname variable from webpack config as string //
// https://github.com/webpack-contrib/sass-loader#additionaldata
$brand: "#{$brandname}";
// !

// a list without standard list styling
@mixin unlist {
    margin: 0;
    padding: 0;
    li {
        list-style: none;
    }
}

ul {
    @include unlist();
}

@function brand-color($name, $initColors: $brand-colors) {
    @if map-has-key($initColors, $name) {
        @return map-get($initColors, $name);
    }
    @if map-has-key($global-colors, $name) {
        @return map-get($global-colors, $name);
    }
    @error "The key #{$name} is not in the map '$brand-colors' or '$global-colors'";
    @return null;
}

@mixin ifbrand($b) {
    @if $b == $brand {
        @content;
    }
}

@mixin ifnotbrand($b) {
    @if $b != $brand {
        @content;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

@mixin ifdisabled() {
    &.disabled,
    &[disabled] {
        @content;
    }
}

@mixin ifnotdisabled() {
    &:not(.disabled):not([disabled]) {
        @content;
    }
}

@mixin ifnotselectedoractive() {
    &:not(.selected):not(.active) {
        @content;
    }
}

@mixin ieonly() {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

// RTE Mixins
@mixin richTextStyles() {
    img {
        height: auto !important;
    }
    iframe {
        max-width: 100%;
    }

    p {
        &:last-child {
            margin-bottom: 0px;
        }
    }

    ul,
    ol {
        li {
            padding-bottom: 7px;
        }
    }
}

@mixin line-clamp($font-size, $line-height, $lines-to-show) {
    @if unitless($line-height) == false {
        $line-height: create-unitless-line-height($font-size, $line-height);
    }

    display: block; // Fallback for non-webkit browsers
    display: -webkit-box;
    font-size: $font-size;
    height: ($font-size * $line-height * $lines-to-show); // Fallback for non-webkit browsers
    line-height: $line-height;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines-to-show;
}

