@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

$AccordionItemMarginBottom: 12px;

.Accordion {
    --triggerPadding: 15px 20px;
    --contentPadding: 20px 60px 20px 85px;
    --textColor: inherit;
    --textColorHover: inherit;
    --backgroundColor: #fff;
    --textColorExpanded: #{brand-color(primary)};
    --backgroundColorExpanded: #fff;
    @include unlist();
}

.AccordionItem {
    --iconRotation: 0deg;
    --timing: 0.2s;

    border: 2px solid var(--backgroundColor);
    transition: border-color var(--timing) ease-in-out;
    margin-bottom: $AccordionItemMarginBottom;
    background: #fff;

    &:last-child {
        margin-bottom: 0px;
    }

    .link {
        -webkit-appearance: button;
        -moz-appearance: button;
        appearance: button;
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    .trigger,
    .link {
        width: 100%;
        padding: var(--triggerPadding);
        min-height: 124px;
        text-align: left;
        color: var(--textColor);
        background-color: var(--backgroundColor);
        transition: all var(--timing) ease-in-out;
        border: 0px;
        border-radius: 0px;
        
        .AccordionHdrWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .AccordionHdr {
                display: flex;
                gap: 10px;
                align-items: center;

                .AccordionIcon {
                    .Icon {
                        padding: 0px 10px;
                        font-size: 2em;

                        @include breakpoint(medium) {
                            font-size: 2.5em;
                            padding: 0px 15px;
                        }
                    }
                }

                .AccordionHdrText {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .AccordionTitle {
                        font-size: rem-calc(15);

                        @include breakpoint(medium) {
                            font-size: rem-calc(20);
                        }

                        @include ifbrand("aant") {
                            @include font_InterSemiBold();
                        }
                    }

                    .AccordionSubTitle {
                        font-size: rem-calc(13);

                        @include breakpoint(medium) {
                            font-size: rem-calc(16);
                        }
                    }
                }
            }

            .AccordionChevron {
                &.Icon {
                    transform: rotate(var(--iconRotation)) translateY(-50%);
                    transition: transform var(--timing) ease-in-out;

                    transform-origin: center;
                    line-height: 0;
                    font-size: 2em;

                    @include breakpoint(medium) {
                        font-size: 2.5em;
                    }
                }
            }
        }



        @include breakpoint(large) {
            font-size: 18px;
        }
    }

    .contentContainer {
        margin-left: 20px;
        margin-right: 20px;

        >div {
            padding: 20px 1px;
            background-color: #fff;

            font-size: rem-calc(15);

            @include breakpoint(medium) {
                font-size: rem-calc(18);
                padding: var(--contentPadding);
            }
        }
    }

    &.expanded {
        --iconRotation: 90deg;
        border-color: var(--backgroundColorExpanded);

        .contentContainer {
            border-top: 1px solid rgba(145, 154, 161, 0.5);

        }

        .trigger {
            color: var(--textColorExpanded);
            background-color: var(--backgroundColorExpanded);
        }
    }


    &:not(.expanded) {

        &:hover,
        &:focus-within {

            .link {
                .Icon {
                    transform: translateX(10px);
                    transition: transform var(--timing) ease-in-out;
                }
            }

            .trigger,
            .link {
                color: var(--textColorHover);
                background-color: #f9f9f9;
                cursor: pointer;
            }
        }
    }
}

.AccordionGroup {

    // They're always white bg, and we don't want additional padding on top of the section margins
    >.grid-container {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}