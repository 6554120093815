@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

.ProgressBar {
    > div {
        height: 7px !important;
        border-radius: 0 !important;
        background-color: brand-color(progress-bar-grey) !important;
        @include breakpoint(small only) {
            height: 4px !important;
        }
        > div {
            height: 7px !important;
            @include breakpoint(small only) {
                height: 4px !important;
            }
            background-color: brand-color(progress-bar) !important;
        }
    }
}
