@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

.AlertBar {
    margin-bottom: 35px;

    .AlertBar-Container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 3px;
        background: #FFF;
        font-size: rem-calc(18);
        width: 100%;
        padding: 15px 30px;

        border: 2px solid brand-color(tertiary);

        @include ifbrand("nrma") {
            border: 2px solid brand-color(tertiary);
        }

        @include ifbrand("racq") {
            border: 2px solid brand-color(tertiary);
        }

        @include ifbrand("aant") {
            border: 2px solid brand-color(secondary);
        }

        @include ifbrand(racwa) {
            border: 1px solid brand-color(primary);
            color: brand-color(primary);
            border-left-width: 8px !important;
        }


        .AlertBar-Icon {
            height: auto;
            display: flex;
            align-items: center;
            margin-right: 8px;

            .Icon {
                color: brand-color(primary);

                @include ifbrand("aant") {
                    color: brand-color(secondary);
                }

                @include ifbrand("nrma") {
                    color: brand-color(tertiary);
                }

                @include ifbrand("ract") {
                    color: brand-color(tertiary);
                }

                @include ifbrand("racv") {
                    color: brand-color(secondary);
                }

                @include ifbrand("racq") {
                    color: brand-color(primary);
                }

                @include ifbrand("racwa") {
                    color: brand-color(primary);
                }

                @include ifbrand("raa") {
                    color: brand-color(primary-alt);
                    &::before {
                        font-size: 45px;
                    }
                }
            }

        }

        .AlertBar-Text {
            @include ifbrand(racv) {
                margin-top: 5px;
            }

            p {
                margin-bottom: 0px;
                line-height: 1.25;
            }
        }
    }
}