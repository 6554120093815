@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

.BatteryIcons {
    margin-bottom: 1rem;

    .battery-inclusions-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background: white;
        height: 100%;
        width: 100%;
        padding: 15px;
        border-radius: $input-radius;

        @include breakpoint(large) {
            min-height: 125px;
        }

        @include ifbrand("racv") {
            color: brand-color(secondary);
        }

        @include ifbrand("ract") {
            color: brand-color(primary);
        }

        @include ifbrand("racq") {
            color: brand-color(primary);
        }

        @include ifbrand("racwa") {
            color: brand-color(primary);
            border: 1px solid brand-color(tertiary);
            min-height: 145px !important;
            border-radius: 0px;
        }

        @include ifbrand("raa") {
            color: brand-color(primary);
        }

        &.disabled {
            opacity: 0.35;
            border: 1px solid brand-color(grey);
            cursor: not-allowed;
            pointer-events: none;
        }


        .inclusion-text {
            font-size: rem-calc(18);
            margin-bottom: 10px;

            @include ifbrand("nrma") {
                @include font_GTEestiProDisplayBold();
            }

            @include ifbrand("racv") {
                @include font_PoppinsSemiBold();
            }

            @include ifbrand("ract") {
                @include font_PoppinsSemiBold();
            }

            @include ifbrand("racq") {
                font-size: rem-calc(16);
                @include font_ArialBold();
            }

            @include ifbrand("racwa") {
                margin-bottom: 15px;
                font-size: rem-calc(22);
                @include font_StagSansBook()
            }

            @include ifbrand("raa") {
                @include font_NationalMedium();
            }

            @include breakpoint(small only) {
                font-size: rem-calc(14);
            }
        }

        .brand-icon {
            font-size: rem-calc(50);

            @include breakpoint(small only) {
                font-size: rem-calc(32);
            }
        }
    }

    &.light {
        .battery-inclusions-box {
            background: brand-color(light-grey);
        }
    }
}