@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";
@import "../../../scss/global";



.MessageBox {
    background: white;
    padding: 25px 60px;
    min-height: 100px;
    margin-bottom: 1rem;
    border-radius: $input-radius;
    width: 100%;

    @include breakpoint(small only) {
        padding: 15px 15px;
    }

    &.error {
        .brand-icon {
            color: brand-color(error);
        }

        @include ifbrand("racwa") {
            @include RACWA_MessageBox_Neutral_Style();
        }

        @include ifbrand("racq") {
            border: 2px solid brand-color(error);
        }

        @include ifbrand("aant") {
            border: 2px solid brand-color(error);
        }
    }

    &.success {
        .brand-icon {
            color: brand-color(primary);

            @include ifbrand("nrma") {
                color: brand-color(secondary);
            }
        }

        @include ifbrand("racwa") {
            @include RACWA_MessageBox_Neutral_Style();
        }
    }

    &.neutral {
        border: 2px solid brand-color(grey);
        background-color: transparent;
        border-radius: 0px !important;

        .brand-icon {
            color: brand-color(error);
        }

        @include ifbrand("racwa") {
            @include RACWA_MessageBox_Neutral_Style();
        }
    }

    &.center {
        text-align: center;

        .message-box-container {
            text-align: center;
            justify-content: center;
        }
    }

    &.flex-start {
        .message-box-container {
            align-items: flex-start;
        }
    }

    &.block {
        .message-box-container {
            display: block;
        }
    }

    &.warning {
        border: 2px solid brand-color(tertiary);

        @include ifbrand("racq") {
            border: 2px solid brand-color(tertiary);
        }

        @include ifbrand("raa") {
            border: 2px solid brand-color(secondary);
        }

        .brand-icon {
            color: brand-color(tertiary);

            @include ifbrand("racv") {
                color: brand-color(secondary);
            }

            @include ifbrand("racq") {
                color: brand-color(secondary);
            }

            @include ifbrand("raa") {
                color: brand-color(primary);
            }
        }
    }

    &.small {
        padding: 15px 25px;
        min-height: unset;

        .message-box-container {
            .icon {
                margin-right: 20px;


                .brand-icon {
                    font-size: rem-calc(55);
                }
            }

            .content-container {
                .content {
                    >b {
                        font-size: rem-calc(22);
                        margin-bottom: 7.5px;

                        @include breakpoint(small only) {
                            font-size: rem-calc(17);
                        }
                    }

                    p {
                        font-size: rem-calc(18);

                        @include breakpoint(small only) {
                            font-size: rem-calc(14);
                        }
                    }
                }
            }
        }
    }

    &.small-text {
        .message-box-container {
            .icon {
                .brand-icon {
                    font-size: rem-calc(18);
                }
            }

            .content-container {
                .content {
                    p:last-child {
                        font-size: rem-calc(18);
                    }

                    @include ifbrand("racwa") {

                        >b,
                        strong {
                            font-size: rem-calc(18);
                        }

                        p,
                        p>b,
                        strong {
                            font-size: rem-calc(16);
                        }

                        p:last-child {
                            font-size: rem-calc(16);
                        }
                    }
                }
            }
        }
    }

    &[data-variant="multiple"] {
        @include breakpoint(medium) {
            height: 100%;
        }

        .message-box-container {
            align-items: flex-start;

            .icon {
                margin-top: -5px;
            }
        }

        .content-container {
            .content {

                >b,
                b {
                    font-size: 1.125rem !important;
                }

                p:last-child {
                    margin-top: 25px !important;
                    margin-left: -75px;
                }
            }
        }
    }

    .message-box-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include breakpoint(small only) {
            align-items: flex-start;
        }

        .icon {
            margin-right: 46px;

            @include breakpoint(small only) {
                margin-right: 16px;
            }

            .brand-icon {
                font-size: rem-calc(80);

                @include breakpoint(small only) {
                    font-size: rem-calc(40);
                }

                @include ifbrand("racwa") {
                    font-size: rem-calc(60);

                    @include breakpoint(small only) {
                        font-size: rem-calc(40);
                    }
                }

                @include ifbrand("aant") {
                    font-size: rem-calc(50);

                    @include breakpoint(small only) {
                        font-size: rem-calc(40);
                    }
                }
            }

            &.sad-face {
                .brand-icon {
                    color: brand-color(error);
                }
            }

            &.time {
                .brand-icon {
                    color: brand-color(primary);
                }
            }
        }

        .content-container {
            .content {
                font-size: rem-calc(22);

                >b,
                strong {
                    font-size: rem-calc(25);
                    margin-bottom: rem-calc(15);

                    @include ifbrand("nrma") {
                        @include font_GTEestiProDisplayBold();
                    }

                    @include ifbrand("racv") {
                        @include font_PoppinsSemiBold();
                    }

                    @include breakpoint(small only) {
                        font-size: rem-calc(18);
                    }
                }

                @include breakpoint(small only) {
                    font-size: rem-calc(16);
                }

                b,
                strong {
                    font-size: rem-calc(22);

                    @include ifbrand("racwa") {
                        font-size: rem-calc(21);
                        @include font_StagSansBook()
                    }

                    @include breakpoint(small only) {
                        font-size: rem-calc(16);
                    }
                }

                p {
                    font-size: rem-calc(22);
                    margin-bottom: rem-calc(15);

                    @include ifbrand("racwa") {
                        font-size: rem-calc(21);
                        @include font_StagSansBook()
                    }
                    
                    @include ifbrand("raa") {
                        font-size: rem-calc(20);
                        @include font_NationalMedium();
                    }

                    @include breakpoint(small only) {
                        font-size: rem-calc(14);
                    }

                    b,
                    strong {
                        @include ifbrand("nrma") {
                            @include font_GTEestiProDisplayBold();
                        }

                        @include ifbrand("racv") {
                            @include font_PoppinsSemiBold();
                        }

                        @include ifbrand("racq") {
                            @include font_FiraSansBold();
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                        margin-top: 7.5px;
                    }
                }
            }
        }
    }
}