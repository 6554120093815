@import "../../../scss/config/all";

$header-shadow: 0 4px 8px rgba(21, 21, 21, 0.12);

header {
    background: #fff;
    height: $headerHeight;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: layer(header);
    box-shadow: $header-shadow;

    @include ifbrand("nrma") {
        background: brand-color(primary);
        box-shadow: none;
    }

    @include ifbrand("ract") {
        box-shadow: none;
    }

    @include breakpoint(small only) {
        height: $headerHeightMobile;
    }

    //#header from SXA template
    #header {
        width: 100%;
    }

    .hdr-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo-container {
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            @include ifbrand("ract") {
                align-items: center;
            }

            @include ifbrand("racq") {
                align-items: center;
            }

            @include ifbrand("racwa") {
                align-items: center;
            }

            @include ifbrand("aant") {
                align-items: center;
            }

            @include ifbrand("raa") {
                align-items: center;
            }

            .brand-logo {
                margin-right: 35px;
                height: auto;
                width: 110px;
                > div {
                    width: inherit;
                }

                @include breakpoint(small only) {
                    width: 56px;
                    margin-right: 15px;
                    > svg {
                        width: inherit;
                    }
                }

                @include ifbrand("racq") {
                    width: 201px;

                    svg {
                        width: 201px;
                    }

                    @include breakpoint(small only) {
                        width: 100px;

                        svg {
                            width: auto;
                        }
                    }
                }

                @include ifbrand("aant") {
                    width: 201px;

                    svg {
                        width: 201px;
                    }

                    @include breakpoint(small only) {
                        width: 100px;

                        svg {
                            width: 100px;
                        }
                    }
                }

                @include ifbrand("raa") {
                    > div {
                        width: inherit;
                    }
                    svg {
                        width: inherit;
                    }
                }
            }

            .page-title-container {
                h1 {
                    margin-bottom: 5px;
                    line-height: 1;
                    letter-spacing: 0px;
                    color: brand-color(primary);

                    @include breakpoint(small only) {
                        margin-bottom: 1px;
                    }

                    @include ifbrand("nrma") {
                        color: #fff;
                    }

                    @include ifbrand("racv") {
                        color: brand-color(secondary);
                    }

                    @include ifbrand("racq") {
                        font-size: rem-calc(35);
                        @include font_FiraSansBold();

                        @include breakpoint(small only) {
                            font-size: rem-calc(25);
                        }
                    }

                    @include ifbrand("racwa") {
                        color: brand-color(primary-alt);
                        font-size: rem-calc(40);
                        @include font_StagSansSemibold();

                        @include breakpoint(small only) {
                            font-size: rem-calc(25);
                        }
                    }

                    @include ifbrand("aant") {
                        font-size: rem-calc(35);
                        margin-bottom: 0px;

                        @include breakpoint(small only) {
                            font-size: rem-calc(22);
                        }
                    }

                    @include ifbrand("raa") {
                        color: brand-color(primary-dark);
                        font-size: rem-calc(35);
                        margin-bottom: 10px;
                        @include font_NationalBold();

                        @include breakpoint(small only) {
                            font-size: rem-calc(22);
                        }
                    }

                }

                .sub-heading {
                    font-size: rem-calc(18);
                    letter-spacing: normal;
                    color: brand-color(primary);

                    @include ifbrand("nrma") {
                        color: #fff;
                    }

                    @include ifbrand("racv") {
                        color: brand-color(secondary);
                    }

                    @include ifbrand("ract") {
                        @include font_PoppinsMedium();
                        font-size: rem-calc(20);
                    }

                    @include ifbrand("racq") {
                        font-size: rem-calc(20);
                    }

                    @include ifbrand("racwa") {
                        font-size: rem-calc(20);
                        @include font_StagSansBook();
                    }

                    @include ifbrand("aant") {
                        font-size: rem-calc(20);
                    }

                    @include ifbrand("raa") {
                        font-size: rem-calc(20);
                        color: brand-color(primary-dark);
                    }

                    @include breakpoint(small only) {
                        font-size: rem-calc(12);
                    }
                }
            }
        }

        .sign-out-btn {
            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #fff;
                text-decoration: none;

                @include ifbrand("racv") {
                    color: brand-color(secondary);
                }

                @include ifbrand("ract") {
                    color: brand-color(primary);
                    @include font_PoppinsMedium();
                }

                @include ifbrand("racq") {
                    color: brand-color(primary);
                }

                @include ifbrand("racwa") {
                    color: brand-color(primary);
                }

                @include ifbrand("raa") {
                    color: brand-color(primary);
                }

                &:hover,
                &:focus {
                    opacity: 0.75;
                }

                .brand-icon {
                    font-size: rem-calc(32);
                    padding-bottom: 10px;

                    @include breakpoint(small only) {
                        font-size: rem-calc(18);
                        padding-bottom: 5px;
                    }
                }

                @include breakpoint(small only) {
                    flex-direction: row;
                    gap: 10px;
                }
            }
        }
    }
}