@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

.ContentBlurb {
    width: 100%;

    &.primary {
        color: brand-color(primary);
    }

    &.bold {
        @include ifbrand("ract") {
            @include font_PoppinsSemiBold();
        }
    }

    p {
        font-size: rem-calc(20);
        text-align: center;

        @include breakpoint(small only) {
            font-size: rem-calc(14);
        }
    }

    &.small-text * {
        font-size: rem-calc(16);
    }

    &.membership-form {
        p {
            text-align: left;
        }
    }
    &.membership-tabcontent {// For RAA road service msg
        .cell {
            padding: rem-calc(27 20);
            border-radius: 4px;
            border-bottom: 4px solid #00349f;
            background: #fff;
            margin-bottom: 1.4375rem;
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
