@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

.MembershipForm {
    margin: 0;

    @include ifbrand("racq") {
        margin: calc(50px - 16px) 0 50px;
    }

    //RACQ Accordion
    .MembershipCTABox {
        background: #fff;
        padding: 25px;
        @include breakpoint("medium") {
            padding: 35px;
        }
        &.success {
            border: 2px solid brand-color(success);
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: $global-radius;

            .Icon,
            .icon {
                color: brand-color(success);
            }
        }

        .message-box-text {
            flex: 1;

            b,
            strong {
                font-size: rem-calc(18);
                line-height: 1.25;
                @include breakpoint("medium") {
                    font-size: rem-calc(24);
                    line-height: 1.5;
                }
                @include ifbrand("racq") {
                    @include font_FiraSansSemiBold();
                }
            }

            p {
                margin-bottom: 0;
                font-size: rem-calc(16);
                @include breakpoint("medium") {
                    font-size: rem-calc(18);
                }
            }
        }

        .message-box-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover {
                cursor: pointer;
            }
            &.no-hover-effect {
                &:hover {
                    cursor: default;
                }
            }

            .message-box-icon,
            .message-box-cta {
                width: 80px;
            }

            .message-box-icon {
                .Icon,
                .icon {
                    font-size: rem-calc(50);
                }
            }

            .message-box-cta {
                .Icon,
                .icon {
                    font-size: rem-calc(40);
                }
            }
        }
    }

    .membership-form-inner {
        @include ifbrand("racq") {
            padding: 50px 0 calc(50px - 16px);
            background: #f9f9f9;
            margin-top: 35px;
            border-radius: $global-radius;

            .first-child-text {
                margin-top: -30px;
            }

            .last-child-text {
                margin-bottom: -30px;
            }
        }

        .input-group-button {
            button {
                min-width: 125px !important;
            }
        }
    }

    // RAA Road Services Tabs
    .TabsContainer {
        .TabButtons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: rem-calc(23);
            .btn {
                border-radius: 4px 0px 0px 4px;
                border: 2px solid #b8b8b8;
                background: #fff;
                color: #000;
                width: 143px;
                &:hover,
                &:focus,
                &:active {
                    border-color: #000;
                    background: #fff !important;
                }
                &.active-tab {
                    border-radius: 0px 4px 4px 0px;
                    border: 2px solid #000;
                    background: #00349f;
                    color: #fff;
                    &:hover,
                    &:focus,
                    &:active {
                        border-color: #000;
                        background: #00349f !important;
                        color: #fff !important;
                    }
                }

                &:first-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                &:last-child {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
    .heading-container {
        h3 {
            @include ifbrand("ract") {
                color: brand-color(primary);
            }
        }
    }
}
