@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

.LoadBlockerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    // background: rgba(#fff, 0.75);
    padding: 40px 0;

    &.NRMA {
        figure {
            position: relative;
            top: 50%;
        }

        #australia-icon {
            z-index: 9999;
            stroke-dasharray: 300;
            stroke-dashoffset: 0;
            animation: dash 2.7s linear infinite;
            width: 91px;
            height: 84px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            .cls {
                fill: none;
                stroke: brand-color(primary);
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 8px;
            }
        }

        @keyframes dash {
            from {
                stroke-dashoffset: 600;
            }

            to {
                stroke-dashoffset: 0;
            }
        }
    }

    &.RACV {
        figure {
            width: 275px;
            height: auto;
        }
    }

    &.RACQ {
        figure {
            display: flex;
            flex-direction: column;
            height: auto;
            object {
               margin: 0 auto;
            }
        }
        figcaption {
            margin-top: 10px;
        }
    }
}

//Common Loader Dots
.LoadBlocker {
    // ! The Parent object MUST be position: relative/absolute so this can be in the right place (100% w/h)
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: none;
    background: rgba(#fff, 0.5);
    align-items: center;
    justify-content: center;
    z-index: layer(loadBlocker);
    text-align: center;
    line-height: 1;
    padding: 25px;

    .ball-pulse,
    .loadingFailureMessage {
        position: absolute;
        position: sticky; // this doesn't work in IE, so it will be ignored
        top: 50%;
        left: 0px;
        width: 100%;
        transform: translateY(-50%);
    }

    .msg {
        padding: 20px 10px;
        margin-top: -50px;
        font-size: 1.4em;
    }

    .loadingFailureMessage {
        color: #c00;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        line-height: 1;
        padding: 0px 10px;

        @include breakpoint(medium) {
            padding: 0px 50px;
        }

        .message {
            font-size: 1.4em;

            a {
                color: inherit;
                text-decoration: underline;

                &:hover,
                &:focus,
                &:active {
                    color: #000;
                    text-decoration: none;
                }
            }
        }

        .btn {
            margin: 5px 0px;
        }

        &.row {
            flex-direction: row;
            justify-content: center;

            .btn {
                margin: 0px 10px;
            }
        }
    }

    .ball-pulse {
        display: block;
        font-size: 0;

        >div {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin: 2px;
            border-radius: 100%;
            animation: ball-pulse 0.65s ease infinite;

            &:nth-child(1) {
                animation-delay: 0ms;
                background-color: brand-color(blocker-dot-1);
            }

            &:nth-child(2) {
                animation-delay: 100ms;
                background-color: brand-color(blocker-dot-2);
            }

            &:nth-child(3) {
                animation-delay: 200ms;
                background-color: brand-color(blocker-dot-3);
            }

            &:nth-child(4) {
                animation-delay: 300ms;
                background-color: brand-color(blocker-dot-4);
            }
        }
    }

    &.fixed-balls {

        .ball-pulse,
        .loadingFailureMessage {
            position: absolute;
        }
    }

    &.fixed-error-message {
        &.error {
            position: relative;
            top: auto;
            left: auto;
            background: none;

            .loadingFailureMessage {
                position: relative !important;
                top: auto;
                left: auto;
                transform: none;
            }
        }
    }

    &.fixed-position {
        position: relative;
        top: auto;
        left: auto;

        .ball-pulse,
        .loadingFailureMessage {
            position: relative !important;
            top: auto;
            left: auto;
            transform: none;
        }
    }

    &.small {
        .ball-pulse {
            >div {
                width: 6px;
                height: 6px;
                margin: 1px;
            }
        }
    }

    &.inline {
        background: none !important;
        width: 56px; // the max width when each ball is at max;

        .ball-pulse {
            position: absolute;
            margin-top: 0px;
        }

        &.small {
            width: 32px;
        }
    }

    &.error,
    &.blocked {
        display: flex;
    }
}

.loading>.show-if-loading {
    display: flex;
}

//Ball Pulse Animation
@keyframes ball-pulse {

    0%,
    60%,
    100% {
        transform: scale(1) translateY(0);
    }

    30% {
        transform: scale(1.5) translateY(-40%);
    }
}