// grid override bug
.grid-container:not(.full) > .grid-padding-x:not(.grid-margin-x) {
    margin-left: 0px;
    margin-right: 0px;
}

@mixin full-width-cell($bp, $padded: false) {
    @if map-has-key($grid-container-padding, $bp) {
        $margin: map-get($grid-container-padding, $bp);
        margin-left: (0 - $margin) / 2;
        margin-right: (0 - $margin) / 2;
        width: calc(100% + #{$margin});

        @if ($padded) {
            padding-left: ($margin / 2);
            padding-right: ($margin / 2);
        }
    }
}

@mixin pull-cell-right($bp) {
    @if map-has-key($grid-container-padding, $bp) {
        $margin: map-get($grid-container-padding, $bp);
        margin-right: (0 - $margin) / 2;
    }
}
@mixin pull-cell-left($bp) {
    @if map-has-key($grid-container-padding, $bp) {
        $margin: map-get($grid-container-padding, $bp);
        margin-left: (0 - $margin) / 2;
    }
}
