@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

.form-container {
    position: relative;
}

//Common
.FieldContainer {
    width: 100%;
    position: relative;

    label:not(.chbx) {
        margin-bottom: 7px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include breakpoint(small only) {
            font-size: rem-calc(16);
        }

        &.icon-left {
            flex-flow: row-reverse;
            justify-content: flex-end !important;

            .brand-icon {
                margin-right: 7px;
                margin-left: 0;
            }
        }

        &.icon-right {
            justify-content: flex-start;

            .brand-icon {
                margin-left: 7px;
            }
        }

        .brand-icon {
            margin-left: 7px;
            font-size: rem-calc(16);
        }

        .required-asterisk {
            margin-left: 3px;
            color: brand-color(error);
            font-size: rem-calc(18);
        }
    }

    .input-group {
        .field-container {
            margin-right: 1rem;
            margin-bottom: 0;
        }
    }

    .field-container {
        position: relative;
        width: 100%;

        @include breakpoint(medium) {
            height: $input-height;
        }

        margin-bottom: 1rem;

        .clear-input,
        .error-input,
        .success-input {
            right: 15px;
            position: absolute;
            top: 50%;
            display: flex;
            transform: translateY(-50%);
        }

        .loader-container {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);

            .loader {
                position: relative;
                height: 23px;
                width: 23px;
                display: inline-block;
                animation: loaderRotate 5.4s infinite;

                &::after {
                    animation: loaderRotate 0.7s ease-in-out 0.1s infinite;
                    background: transparent;
                }

                &::after,
                &::before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    border-width: 2.75px;
                    border-color: brand-color(primary) brand-color(primary) transparent transparent;
                    border-style: solid;
                    border-radius: 20px;
                    box-sizing: border-box;
                    top: 0;
                    left: 0;
                    animation: loaderRotate 0.7s ease-in-out infinite;
                }
            }
        }

        .clear-input {
            cursor: pointer;

            @include ifbrand("racv") {
                color: brand-color(primary-alt);
            }

            @include ifbrand("racq") {
                color: brand-color(primary-alt);
            }

            @include ifbrand("raa") {
                color: brand-color(primary-alt);
                font-size: rem-calc(22);
            }

            &:hover,
            &:focus {
                color: brand-color(primary);

                @include ifbrand("racv") {
                    color: brand-color(primary-alt-hover);
                }

                @include ifbrand("racq") {
                    color: brand-color(primary-alt-hover);
                }
            }
        }

        .with-clear-input {
            right: 37.5px;
        }

        .error-input {
            .brand-icon {
                color: brand-color(error);
            }
        }

        .success-input {
            .brand-icon {
                color: brand-color(primary);
            }
        }
    }
}

.AutocompleteField {
    select {
        @include ifbrand("racv") {
            @include btn-tertiary-outline();

            option {
                background: #fff;
            }
        }

        @include ifbrand("ract") {
            @include btn-tertiary-outline();
            @include font_PoppinsMedium();

            option {
                background: #fff;
            }
        }

        @include ifbrand("racq") {
            @include btn-tertiary-outline();
            font-weight: 700;

            option {
                background: #fff;
            }
        }

        @include ifbrand("racwa") {
            @include btn-tertiary-outline();

            option {
                background: #fff;
            }
        }

        width: 135px;
        text-align: left;

        @include breakpoint(small only) {
            width: 115px;
        }
    }

    .input-group {
        margin-bottom: 0;

    
        .ModalResultsList {
            margin-top: -10px;
        }
    }
}

.ModalResultsList {
    position: absolute;
    background: white;
    width: 100%;
    border-radius: $input-radius;
    margin-top: 2px;
    z-index: layer(modal);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    max-height: 320px;
    overflow: auto;
    top: 100%;

    ul {
        @include unlist();
        padding: 0px 0px;

        li {
            cursor: pointer;
            padding: 10px 20px;
            border-bottom: 1px solid brand-color(light-grey);

            &:hover:not(.no-results-message),
            &:focus:not(.no-results-message),
            &.active:not(.no-results-message),
            &:active:not(.no-results-message) {
                color: brand-color(primary);
                background: brand-color(light-grey);
            }

            &.no-results-message {
                cursor: default;
            }
        }
    }
}

.DatePickerField {
    .react-datepicker-wrapper {
        display: block;

        .react-datepicker__input-container {
            display: block;

            .date-picker-custom-input {
                position: relative;

                input {
                    cursor: pointer;
                }

                .brand-icon {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    padding-right: 20px;
                    transform: translateY(-50%);
                    font-size: 20px;

                    @include ifbrand("racv") {
                        color: brand-color(primary-alt);
                    }

                    @include ifbrand("ract") {
                        color: brand-color(primary-alt);
                    }

                    @include ifbrand("raa") {
                        color: brand-color(primary-alt);
                        font-size: 32px;
                    }

                    @include breakpoint(small only) {
                        padding-right: 10px;
                    }
                }
            }
        }
    }

    .react-datepicker-popper {
        z-index: 9999;

        .react-datepicker__header {
            padding: 8px 18px;

            .react-datepicker__current-month {
                display: none;
            }

            .react-datepicker__header__dropdown--select {
                select {
                    height: 25px;
                    font-size: 16px;
                    background-size: 13px 28px !important;
                    background-position: right -20px center !important;
                    padding-right: 25px !important;
                    padding-left: 13px;
                    margin-bottom: 8px;
                }
            }
        }

        .react-datepicker__navigation {
            &.react-datepicker__navigation--previous {
                margin-left: -5px;
                top: 5px;
            }

            &.react-datepicker__navigation--next {
                margin-right: -5px;
                top: 5px;
            }
        }
    }

    .react-datepicker__month-container {
        float: none;

        .react-datepicker__day {
            &:hover {
                @include ifbrand("nrma") {
                    background-color: brand-color(primary-alt);
                }
            }
        }

        .react-datepicker__day--selected {
            @include ifbrand("nrma") {
                background-color: brand-color(primary);
            }
        }
    }

    .date-picker-footer-btn {
        display: block;
        margin: 10px auto;
    }
}

@keyframes loaderRotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.RadioBtnGroupSelected {
    margin-bottom: 1rem !important;

    ul {
        li {
            .btn {
                border-radius: $input-radius;
                border-color: transparent;
                color: brand-color(primary);

                @include ifbrand("nrma") {
                    @include font_GTEestiProDisplayRegular();
                }

                @include ifbrand("racv") {
                    @include font_SuisseIntlRegular();
                    border-width: 2px !important;
                }

                @include ifbrand("racq") {
                    border-width: 2px !important;
                }

                @include ifbrand("racwa") {
                    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
                    @include font_StagSansBook();
                }

                @include breakpoint(small only) {
                    height: 50px;
                }

                text-align: left;
                margin-bottom: 1rem;
                word-break: break-word;

                &:hover,
                &:focus {
                    border-color: brand-color(primary) !important;

                    @include ifbrand("racv") {
                        color: brand-color(secondary) !important;
                    }
                }

                .icon,
                .Icon {
                    color: brand-color(grey);

                    @include ifbrand("racv") {
                        color: brand-color(primary-alt);
                    }

                    @include ifbrand("ract") {
                        color: brand-color(primary-alt);
                    }

                    @include ifbrand("racq") {
                        color: brand-color(primary);
                    }
                    
                    @include ifbrand("aant") {
                        color: brand-color(primary);
                    }

                    @include ifbrand("raa") {
                        color: brand-color(primary-alt);
                    }
                }

                .Icon+span {
                    @include line-clamp($font-size: 22px, $line-height: 1, $lines-to-show: 1);
                }
            }
        }
    }
}