@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

.BookingSteps {
    height: $bookingStepsContainerHeightDesktop;
    position: relative;
    background: #fff;

    @include breakpoint(small only) {
        height: $bookingStepsContainerHeightMobile;
        border-bottom-width: 5px;
    }

    .booking-steps-inner {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        padding: 20px 0;
        height: $bookingStepsContainerHeightDesktop;

        @include breakpoint(small only) {
            align-items: center;
            height: $bookingStepsContainerHeightMobile;
            padding: 0;
        }
    }
}

.BookingStep {
    width: 100%;
    position: relative;
    padding: 0 20px;

    @include breakpoint(small only) {
        padding: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid brand-color(grey);

        &:last-child {
            border-right: none;
        }

        &[data-active-toggle] {
            background: brand-color(primary);

            .title-container {

                .title,
                .sub-title {
                    color: white !important;
                }

                .icon {
                    .brand-icon {
                        color: white !important;
                    }
                }
            }
        }
    }

    .booking-step-container {
        &:focus {
            @include ifbrand("nrma") {
                outline-color: brand-color(primary);
            }

            @include ifbrand("racv") {
                outline-color: brand-color(primary-alt);
            }
        }

        @include breakpoint(medium) {
            height: 100%;
            padding-top: 5px;
            // padding-bottom: 10px;
        }
    }

    &[data-active-step] {
        .title-container {
            .title {
                color: brand-color(primary);

                @include ifbrand("racv") {
                    color: brand-color(primary-alt);
                }

                @include ifbrand("racwa") {
                    @include font_StagSansBook();
                }
            }

            .icon {
                .brand-icon {
                    color: brand-color(primary);

                    @include ifbrand("racv") {
                        color: brand-color(primary-alt);
                    }
                }
            }
        }
    }

    &[data-completed-step] {
        .title-container {
            .title {
                color: brand-color(primary);

                @include ifbrand("racv") {
                    color: brand-color(primary-alt);
                }
            }

            .icon {
                .brand-icon {
                    color: brand-color(primary);

                    @include ifbrand("racv") {
                        color: brand-color(primary-alt);
                    }
                }
            }
        }

        &:after {
            background: brand-color(primary);
            width: 2px;

            @include ifbrand("racv") {
                background: brand-color(light-grey-alt);
            }

            @include ifbrand("raa") {
                background: brand-color(light-grey-alt);
            }
        }
    }

    @include breakpoint(medium up) {
        &:after {
            content: "";
            position: absolute;
            width: 1px;
            background: brand-color(grey);
            height: 100%;
            right: 0;
            top: 0;
        }
    }

    @include breakpoint(large) {
        &:first-child {
            padding-left: calc((100% - 1016px) / 2);
        }

        &:last-child {
            padding-right: calc((100% - 1016px) / 2);

            &:after {
                display: none;
            }
        }
    }

    .title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 8px;

        @include ifbrand("raa") {
            margin-bottom: 12px;
        }

        @include breakpoint(small only) {
            flex-direction: column;
            margin-bottom: 0px;
        }

        .icon {
            margin-right: 10px;
            margin-bottom: 4px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 36px;

            &.location {
                margin-right: 2px;
            }

            @include breakpoint(small only) {
                margin-right: 0;
                justify-content: center;
                margin-bottom: 5px;
            }

            .brand-icon {
                font-size: rem-calc(32);
                color: brand-color(grey);

                @include ifbrand("nrma") {
                    color: brand-color(secondary);
                }

                @include breakpoint(medium down) {
                    font-size: rem-calc(24);
                }
            }
        }

        .title {
            font-size: rem-calc(22);
            color: brand-color(grey);

            @include ifbrand("nrma") {
                color: brand-color(secondary);
            }

            @include ifbrand("ract") {
                @include font_PoppinsRegular();
                font-size: rem-calc(23);
            }

            @include ifbrand("racwa") {
                @include font_StagSansRegular();
                font-size: rem-calc(20);
            }

            @include ifbrand("raa") {
                @include font_NationalMedium();
                font-size: rem-calc(20);
            }

            @include breakpoint(medium down) {
                font-size: rem-calc(16);
            }

            @include breakpoint(small only) {
                font-size: rem-calc(12);
            }
        }
    }

    .sub-title-container {
        color: #000;
        line-height: 1.25;

        @include ifbrand("nrma") {
            font-size: rem-calc(20);
            @include font_GTEestiProDisplayBold();
        }

        @include ifbrand("racv") {
            font-size: rem-calc(18);
            @include font_SuisseIntlBold();
        }

        @include ifbrand("ract") {
            font-size: rem-calc(18);
            @include font_PoppinsSemiBold();
            color: brand-color(secondary);
        }

        @include ifbrand("racq") {
            @include font_ArialBold();
        }


        @include ifbrand("racwa") {
            font-size: rem-calc(18);
            @include font_StagSansBook()
        }

        @include ifbrand("aant") {
            font-size: rem-calc(18);
            color: brand-color(primary);
            @include font_InterBold()
        }

        @include ifbrand("raa") {
            font-size: rem-calc(20);
            color: brand-color(primary-dark);
            @include font_NationalBold()
        }

        @include breakpoint(medium down) {
            font-size: rem-calc(16);
        }

        @include breakpoint(small only) {
            display: none;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            background: white;
            z-index: layer(mobileSubTitle);
            top: 100%;
            width: 100vw;
            padding: 20px;
            min-height: 50px;

            &.visible-mobile {
                display: flex;
            }
        }

        .sub-title-values {
            @include breakpoint(medium) {
                @include line-clamp($font-size: 20px, $line-height: 1.25, $lines-to-show: 3);
            }

            .sub-title-placeholder {
                color: brand-color(grey);
                font-size: rem-calc(16);

                @include ifbrand("racq") {
                    @include font_ArialRegular();
                }
            }
        }

        .sub-title-btn {
            @include breakpoint(medium) {
                display: none;
            }
        }
    }
}

.ProgressBar {
    z-index: layer(progressBar);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: -28px;

    @include breakpoint(small only) {
        margin-bottom: 0px;
    }
}