@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

$aniTime: 0.35s;
$racq-grey: #706f75;

// Common styles for battery cards
%battery-card-common {
    border: 3px solid brand-color(card-border);
    border-radius: $global-radius;
    height: 100% !important;

    .battery-card-inner {
        height: 100% !important;
        background: white;
        padding: 20px;

        img {
            margin: 0 auto;
            text-align: center;
            max-width: 190px;
            height: auto;
        }

        .description {
            text-align: center;
            margin-bottom: 10px;
            font-size: rem-calc(20);
            padding: 20px 10px 0;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include ifbrand("aant") {
                @include font_InterSemiBold();
                font-size: rem-calc(18);
            }

            @include ifbrand("raa") {
                @include font_NationalMedium(); // No National semibold, use Medium weight instead
                font-size: rem-calc(18);
                line-height: normal;
            }

            @include breakpoint(small only) {
                font-size: rem-calc(16);
            }
        }

        .warranty {
            font-size: rem-calc(26);
            text-align: center;
            border-top: 1px solid brand-color(card-border);
            border-bottom: 1px solid brand-color(card-border);
            padding: 25px 0;
            margin: 0 -20px;

            @include breakpoint(small only) {
                font-size: rem-calc(20);
            }

            @include ifbrand("nrma") {
                @include font_GTEestiProDisplayBold();
            }

            @include ifbrand("racv") {
                @include font_SuisseIntlBold();
            }

            @include ifbrand("ract") {
                @include font_PoppinsBold();
            }

            @include ifbrand("racq") {
                @include font_ArialBold();
            }

            @include ifbrand("racwa") {
                @include font_StagSansBook();
            }

            @include ifbrand("aant") {
                @include font_InterBold();
                font-size: rem-calc(25);
            }

            @include ifbrand("raa") {
                @include font_NationalBold();
                font-size: rem-calc(25);
            }
        }

        .prices-container {
            text-align: center;
            margin: 0 -20px;
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            .price-box {
                padding: 25px 0;
                flex-basis: 50%;
                width: 50%;
                border-bottom: 1px solid brand-color(card-border);

                @include ifbrand("racq") {
                    border-left: 1px solid brand-color(card-border);
                    border-right: 1px solid brand-color(card-border);
                }

                @include ifbrand("racwa") {
                    @include font_StagSansBook();
                }

                @include ifbrand("aant") {
                    border-left: 1px solid brand-color(card-border);
                    border-right: 1px solid brand-color(card-border);
                }

                @include ifbrand("raa") {
                    border-left: 1px solid brand-color(card-border);
                    border-right: 1px solid brand-color(card-border);
                }

                &[data-membership-status] {
                    @include ifbrand("nrma") {
                        background: brand-color(primary);

                        .customer-type,
                        .price {
                            color: brand-color(light-grey);
                        }
                    }

                    @include ifbrand("racv") {
                        background: brand-color(primary-alt);

                        .customer-type,
                        .price {
                            color: brand-color(light-grey);
                        }
                    }

                    @include ifbrand("ract") {
                        background: brand-color(primary);

                        .customer-type,
                        .price {
                            color: brand-color(light-grey);
                        }
                    }

                    @include ifbrand("racwa") {
                        background: brand-color(tertiary);

                        .customer-type,
                        .price {
                            color: brand-color(primary);
                        }
                    }

                    @include ifbrand("raa") {
                        background: #fff;

                        .customer-type,
                        .price {
                            color: brand-color(primary);
                        }

                        .customer-type {
                            font-size: rem-calc(18);

                            @include breakpoint(medium down) {
                                font-size: rem-calc(15);
                            }
                        }
                    }
                }

                &[data-member-price] {
                    @include ifbrand("racq") {
                        background: brand-color(primary);
                        border: none;

                        .customer-type,
                        .price {
                            color: #fff;
                        }
                    }
                }

                &[data-nonmember-price] {
                    @include ifbrand("racq") {
                        background: #fff;

                        .customer-type,
                        .price {
                            color: $racq-grey !important;
                        }
                    }

                    @include ifbrand("aant") {

                        .customer-type,
                        .price {
                            color: brand-color(primary-alt);
                        }
                    }
                }

                .price {
                    font-size: rem-calc(30);
                    margin-bottom: 5px;

                    @include ifbrand("nrma") {
                        @include font_GTEestiProDisplayBold();
                    }

                    @include ifbrand("racv") {
                        @include font_SuisseIntlBold();
                    }

                    @include ifbrand("racq") {
                        @include font_ArialBold();
                    }

                    @include ifbrand("aant") {
                        @include font_InterBold();
                    }

                    @include ifbrand("raa") {
                        @include font_NationalBold();
                    }
                }

                .customer-type {
                    font-size: rem-calc(22);

                    @include ifbrand("raa") {
                        font-size: rem-calc(18);
                    }

                    @include breakpoint(medium down) {
                        font-size: rem-calc(15);
                    }
                }

                .customer-type,
                .price {
                    color: brand-color(placeholder-grey);

                    @include ifbrand("ract") {
                        color: brand-color(body-font-color);
                    }

                    @include ifbrand("racq") {
                        color: brand-color(primary);
                    }

                    @include ifbrand("aant") {
                        color: brand-color(primary);
                    }

                    @include ifbrand("raa") {
                        color: brand-color(primary);
                    }
                }
            }
        }

        .specification-container {
            .hdr-inner {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 25px 0 13px;
                font-size: rem-calc(24);

                @include breakpoint(small only) {
                    font-size: rem-calc(18);
                }

                @include ifbrand("nrma") {
                    @include font_GTEestiProDisplayBold();
                }

                @include ifbrand("racv") {
                    @include font_SuisseIntlBold();
                }

                @include ifbrand("ract") {
                    @include font_PoppinsBold();
                }

                @include ifbrand("racq") {
                    @include font_ArialBold();
                }

                @include ifbrand("racwa") {
                    @include font_StagSansBook();
                }

                @include ifbrand("aant") {
                    @include font_InterBold();
                }

                @include ifbrand("raa") {
                    @include font_NationalBold();
                }

                .toggle-arrow {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    .brand-icon {
                        transform: none;
                        transition: all $aniTime ease-in-out;
                        font-size: rem-calc(34); //temp fix - remove based on new chevron icon set?

                        @include breakpoint(small only) {
                            font-size: rem-calc(28);
                        }

                        &.rotate {
                            transform: rotate(-180deg);
                        }
                    }
                }
            }

            .content {
                max-height: 0;
                opacity: 0;
                visibility: hidden;
                overflow: hidden;
                transition: max-height $aniTime ease-in-out, visibility $aniTime ease-in-out, opacity $aniTime ease-in-out, overflow $aniTime ease-in-out;

                &.visible {
                    max-height: 250px;
                    opacity: 1;
                    visibility: visible;
                    overflow: visible;
                }

                &.hidden-mobile {
                    @include breakpoint(small only) {
                        max-height: 0;
                        opacity: 0;
                        visibility: hidden;
                        overflow: hidden;
                    }
                }

                ul {
                    width: 100%;

                    li {
                        margin-bottom: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &:last-child {
                            margin-bottom: 18px;
                        }

                        .type-name {
                            @include ifbrand("racq") {
                                color: $racq-grey !important;
                                @include font_ArialBold();
                            }

                            @include ifbrand("racwa") {
                                @include font_StagSansBook();
                            }
                        }

                        .type-value {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            @include ifbrand("nrma") {
                                @include font_GTEestiProDisplayBold();
                            }

                            @include ifbrand("racv") {
                                @include font_SuisseIntlBold();
                            }

                            @include ifbrand("ract") {
                                @include font_PoppinsBold();
                            }

                            @include ifbrand("racq") {
                                @include font_ArialBold();
                            }

                            @include ifbrand("racwa") {
                                @include font_StagSansSemibold();
                            }

                            .brand-icon {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .modal-icon {
        display: flex;
        align-items: center;
        width: 30px; //if modal popup content is empty
        margin-bottom: 3px;

        span {
            font-size: rem-calc(20);
            color: brand-color(primary);

            @include ifbrand("racv") {
                color: brand-color(primary-alt);
            }

            @include ifbrand("aant") {
                font-size: rem-calc(15);
            }

            @include ifbrand("raa") {
                color: brand-color(primary-alt);
            }
        }
    }
}

.BatteryCard {
    @extend %battery-card-common;

    &[data-standard-battery] {
        border-color: brand-color(primary);

        @include ifbrand("racq") {
            border-color: brand-color(primary-alt);
        }

        @include ifbrand("racwa") {
            border-color: brand-color(charcoal-grey);
            border-width: 1px;
        }

        @include ifbrand("aant") {
            border-color: brand-color(card-border);
        }
    }

    &[data-featured-battery] {
        border-color: brand-color(primary-alt);

        @include ifbrand("racv") {
            border-color: brand-color(secondary);
        }

        @include ifbrand("racq") {
            border-color: brand-color(primary);
        }

        @include ifbrand("racwa") {
            border-color: brand-color(charcoal-grey);
            border-width: 1px;
        }

        @include ifbrand("raa") {
            border-color: brand-color(primary);
        }
    }

    .featured-bars {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        .most-popular,
        .standard-fitment {
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: rem-calc(20);
            color: #fff;

            @include ifbrand("racwa") {
                @include font_StagSansBook();
                font-size: rem-calc(22);
            }

            @include ifbrand("aant") {
                @include font_InterBold();
            }

            @include ifbrand("raa") {
                @include font_NationalBold();
                min-height: 65px;
            }

            @include breakpoint(small only) {
                font-size: rem-calc(17);
            }
        }

        .most-popular {
            background: brand-color(primary-alt);

            @include ifbrand("racv") {
                background: brand-color(secondary);
            }

            @include ifbrand("racq") {
                background: brand-color(primary);
            }

            @include ifbrand("racwa") {
                background: brand-color(primary-dark);
            }

            @include ifbrand("aant") {
                background: brand-color(primary);
            }

            @include ifbrand("raa") {
                background: brand-color(primary);
            }
        }

        .standard-fitment {
            background: brand-color(primary);

            @include ifbrand("nrma") {
                @include font_GTEestiProDisplayBold();
            }

            @include ifbrand("racv") {
                @include font_SuisseIntlRegular();
                background: brand-color(primary-alt);
            }

            @include ifbrand("ract") {
                @include font_PoppinsBold();
            }

            @include ifbrand("racq") {
                background: brand-color(primary-alt);
            }

            @include ifbrand("racwa") {
                background: brand-color(charcoal-grey);
            }

            @include ifbrand("aant") {
                background: brand-color(primary-alt);
            }

            @include ifbrand("raa") {
                background: brand-color(primary-alt-hover);
            }
        }
    }
}

.BatteryCardConfirm {
    @extend .BatteryCard;
    height: auto !important;

    &[data-booking-confirmed] {
        .battery-card-inner {
            padding-bottom: 20px;
        }
    }

    .battery-card-inner {
        text-align: center;
        padding-bottom: 0;

        img {
            margin-bottom: 20px;
        }

        .prices-container {
            .price-box {
                width: 100%;
                flex-basis: 100%;

                @include ifbrand("nrma") {
                    background: brand-color(primary);

                    .customer-type,
                    .price {
                        color: brand-color(light-grey);
                    }
                }

                @include ifbrand("racv") {
                    background: brand-color(primary-alt);

                    .customer-type,
                    .price {
                        color: brand-color(light-grey);
                    }
                }

                @include ifbrand("ract") {
                    background: brand-color(primary);

                    .customer-type,
                    .price {
                        color: brand-color(light-grey);
                    }
                }

                @include ifbrand("racq") {
                    background: brand-color(primary);
                    border: none;

                    .customer-type,
                    .price {
                        color: #fff;
                    }
                }

                @include ifbrand("racwa") {
                    background: brand-color(tertiary);

                    .customer-type,
                    .price {
                        color: brand-color(primary);
                    }
                }

                @include ifbrand("raa") {

                    .customer-type,
                    .price {
                        color: brand-color(primary);
                    }

                    .customer-type {
                        font-size: rem-calc(18);
                    }
                }
            }
        }

        .specification-container {
            .content {
                ul {
                    li {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}