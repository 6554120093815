@import "./src/brand-settings/brands/racv/styles/_brand-variables.scss"; $brandname: racv;
@import "../../../scss/config/all";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.BatteryCardSlider {
    margin: 20px auto 70px auto;

    @include breakpoint(large) {
        width: calc(1400px - 30px);
    }

    @include breakpoint(small only) {
        width: calc(100% - 15px);
    }

    .slick-track {
        display: flex !important;
        align-items: flex-end;
    }

    .slick-list {
        overflow: visible;

        @include breakpoint(large) {
            overflow: hidden;
        }
    }

    .slick-slide>div {
        height: 100%;
    }

    .slick-slide {
        margin: 0 15px;

        // height: inherit !important;
        @include breakpoint(small only) {
            margin: 0 7.5px;
        }
    }

    .slick-prev {
        left: -7.5px;

        &:before {
            content: $brand-icon-arrow-slick-prev; //brand-icon brand-icon-slick-next

            @include ifbrand("nrma") {
                content: $brand-icon-arrow-circle-slick-prev;
            }
        }
    }

    .slick-next {
        right: -7.5px;

        &:before {

            content: $brand-icon-arrow-slick-next; //brand-icon brand-icon-slick-next

            @include ifbrand("nrma") {
                content: $brand-icon-arrow-circle-slick-next;
            }
        }
    }


    .slick-next,
    .slick-prev {
        z-index: layer(slickArrows);
        width: 55px;
        height: 55px;

        @include ifbrand("nrma") {
            top: 46%;
        }

        &:before {
            opacity: 1;

            @include ifbrand("nrma") {
                color: brand-color(primary);
                font-size: rem-calc(55);

                @include breakpoint(small only) {
                    font-size: rem-calc(45);
                }
            }

            @include ifbrand("racv") {
                font-size: rem-calc(18);
                background: brand-color(tertiary);
                border-radius: 50%;
                padding: 15px;
                color: brand-color(secondary);
            }

            @include ifbrand("racq") {
                font-size: rem-calc(18);
                background: brand-color(tertiary);
                border-radius: 50%;
                padding: 15px;
                color: brand-color(primary);
            }

            @include ifbrand("ract") {
                font-size: rem-calc(18);
                background: brand-color(tertiary);
                border-radius: 50%;
                padding: 15px;
                color: #fff;
            }

            @include ifbrand("racwa") {
                font-size: rem-calc(18);
                background: brand-color(tertiary);
                border-radius: 50%;
                padding: 15px;
                color: brand-color(primary-alt);
            }

            @include ifbrand("aant") {
                font-size: rem-calc(18);
                background: brand-color(primary);
                border-radius: 50%;
                padding: 15px;
                color: #fff;
            }

            @include ifbrand("raa") {
                font-size: rem-calc(18);
                background: brand-color(primary);
                border-radius: 50%;
                padding: 15px;
                color: #fff;
            }

            font-family: "clubassist-icons-icomoon";
        }

        &:hover {
            &:before {
                @include ifbrand("nrma") {
                    color: brand-color(primary-hover);
                }

                @include ifbrand("racv") {
                    background: brand-color(tertiary-hover);
                }

                @include ifbrand("ract") {
                    background: brand-color(tertiary-hover);
                }
            }
        }

        &:focus {
            @include ifbrand("racv") {
                outline: 2px solid brand-color(primary-alt);
                border-radius: 50%;
            }

            @include ifbrand("ract") {
                outline: 2px solid brand-color(primary-alt);
                border-radius: 50%;
            }

            &:before {
                @include ifbrand("nrma") {
                    color: brand-color(primary-focus);
                }

            }
        }
    }

    .slick-disabled {
        display: none !important;
    }

    .slick-dots {
        bottom: -35px;

        li {
            button {
                margin-top: -0.5px;
                margin-left: 0.25px;

                &:focus {
                    @include ifbrand("racv") {
                        outline: 2px solid brand-color(primary-alt);
                        border-radius: 50%;
                    }

                    @include ifbrand("ract") {
                        outline: 2px solid brand-color(primary-alt);
                        border-radius: 50%;
                    }

                    @include ifbrand("racq") {
                        outline: 2px solid brand-color(primary-alt);
                        border-radius: 50%;
                    }
                }

                &:before {
                    font-size: rem-calc(14);
                }
            }
        }
    }
}